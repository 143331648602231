import React from "react"

import TileContainer from "@pag/center/components/tile/TileContainer"
import { ROUTES } from "@pag/center/views/routes"
import lists from "@pag/center/views/settingsScreen/listContents/settingsListContents"
import { LauncherTile } from "@pag/center/views/launcherConfig/types"
import { IconType } from "@pag/center/components/icon/iconType"

function tile(text: string, iconType: IconType, url: string): Readonly<LauncherTile> {
  return Object.freeze({ text, iconType, url })
}

function createTileComponent(tile: LauncherTile) {
  return (
    <div key={tile.url} className="tile-list__item">
      <TileContainer
        isSmall={false}
        text={tile.text}
        iconType={tile.iconType}
        url={tile.url}
      />
    </div>
  )
}

// big tiles
export const carTile = tile("CenterMainScreen_Tiles_Car", IconType.icon_car_front_j1, ROUTES.CAR)
export const mediaTile = tile("CenterMainScreen_Tiles_Media", IconType.icon_note, ROUTES.MEDIA_PLAYER)
export const navigationTile = tile("CenterMainScreen_Tiles_Navigation", IconType.icon_north_arrow, ROUTES.NAVIGATION)
export const phoneTile = tile("CenterMainScreen_Tiles_Phone", IconType.icon_phone_receiver, ROUTES.PHONE)
export const carPlayTile = tile("CenterMainScreen_Tiles_Carplay", IconType.icon_apple_carplay, ROUTES.CARPLAY)
export const androidAutoTile = tile("CenterMainScreen_Tiles_AndroidAuto", IconType.icon_android_auto, ROUTES.ANDROID_AUTO)

// all available tiles in the initial order
export const tiles: Readonly<LauncherTile[]> = Object.freeze([
  carTile,
  mediaTile,
  navigationTile,
  phoneTile,
  carPlayTile,
  tile("CenterMainScreen_Tiles_Settings", IconType.icon_setting, ROUTES.SETTINGS),
  tile("CenterMainScreen_Tiles_Charge", IconType.icon_charging_active, ROUTES.CHARGING),
  tile("CenterMainScreen_Tiles_AC", IconType.icon_climate, ROUTES.CLIMATE),
  tile("CenterMainScreen_Tiles_Devices", IconType.icon_connect, ROUTES.DEVICES),
  tile("CenterMainScreen_Tiles_Service", IconType.icon_predictive_maintenance, ROUTES.MAINTENANCE),
  tile("CenterMainScreen_Tiles_Updates", IconType.icon_update_center, ROUTES.UPDATES),
  tile("CenterMainScreen_Tiles_Notification", IconType.icon_notification, ROUTES.NOTIFICATION_CENTER),
  Object.freeze({ text: "Info", iconType: IconType.icon_information_outline, settings_list: lists.Settings_Info }),
  tile("CenterMainScreen_Tiles_Calendar", IconType.icon_calendar, ROUTES.CALENDAR),
  tile("CenterMainScreen_Tiles_Weather", IconType.icon_weather, ROUTES.WEATHER),
  tile("CenterMainScreen_Tiles_News", IconType.icon_news, ROUTES.NEWS),
  tile("CenterMainScreen_Tiles_BCall", IconType.icon_road_side_assistance, ROUTES.PHONE),
  tile("CenterMainScreen_Tiles_SportChrono", IconType.icon_timer, ROUTES.SPORTS_CHRONO),
  tile("CenterMainScreen_Tiles_HomeLink", IconType.icon_homelink, ROUTES.HOME_LINK),
  Object.freeze({ text:"CenterMainScreen_Tiles_eBAL", iconType: IconType.icon_manual, settings_list: lists.Settings_Info })
])

export const bigTiles: Readonly<LauncherTile[]> = Object.freeze([
  carTile,
  mediaTile,
  navigationTile,
  phoneTile,
  carPlayTile,
  androidAutoTile
])

export const smallTiles: Readonly<LauncherTile[]> = Object.freeze(
  tiles.filter((tile: Readonly<LauncherTile>) => !bigTiles.includes(tile))
)

// @NOTE(kirill): it’s only used in centerPassenger
export function bigTileList(showAppleCarplay: boolean) {
  if (showAppleCarplay) {
    return bigTiles.map((tile) => createTileComponent(tile))
  }
  return bigTiles.filter((tile) => tile !== carPlayTile).map((tile) => createTileComponent(tile))
}
