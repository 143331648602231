import {
  LauncherTileState,
  LauncherTileActionType,
  UPDATE_LAUNCHER_BIG_TILE_ORDER,
  UPDATE_LAUNCHER_SMALL_TILE_ORDER
} from "./types"
import { bigTiles, smallTiles } from "@pag/center/components/launcher/launcherTiles"

const launcherTileInitialState: LauncherTileState = {
  bigTiles: bigTiles.slice(),
  smallTiles: smallTiles.slice()
}

export function launcherTileReducer(
  state = launcherTileInitialState,
  action: LauncherTileActionType
): LauncherTileState {
  switch (action.type) {
    case UPDATE_LAUNCHER_BIG_TILE_ORDER:
      return {
        ...state,
        bigTiles: action.payload.tiles
      }
    case UPDATE_LAUNCHER_SMALL_TILE_ORDER:
      return {
        ...state,
        smallTiles: action.payload.tiles
      }
    default:
      return state
  }
}
