import {
  settingsScreenState,
  SET_VISIBLE_SETTINGS_LIST,
  SET_CURRENT_RADIO_BUTTON_LIST_ITEM,
  Action,
  UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE,
  settingsState,
  setPreferenceValueAction,
  SET_PREFERENCE_VALUE,
} from "./types"
import lists from "@pag/center/views/settingsScreen/listContents/settingsListContents"
import settingDefs from "./definitions"

export function settingsScreenReducer(
  state: settingsScreenState = {
    visibleList: lists.settings_MainList,
    currentRadioButtonListItem: "",
    detailsScreenOverlayVisible: false
  },
  action: Action
): settingsScreenState {
  switch (action.type) {
    case SET_VISIBLE_SETTINGS_LIST:
      return { ...state, visibleList: action.visibleList }
    case SET_CURRENT_RADIO_BUTTON_LIST_ITEM:
      return { ...state, currentRadioButtonListItem: action.itemName }
    case UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE:
      return { ...state, detailsScreenOverlayVisible: action.visible }
    default:
      return state
  }
}

const lang: string = localStorage.getItem("locale") || "de_DE"

export function settingsReducer(
  state: settingsState = Object.freeze({
    Settings_System_DateAndTime_SetTimeZoneAutomatically: false,
    Settings_System_DateAndTime_AutomaticallySetDateAndTime: false,
    Settings_System_LanguageAndKeyboard_Language: settingDefs.langs[lang],
    Settings_System_LanguageAndKeyboard_Keyboard: [ settingDefs.langs[lang] ],
    Settings_System_DateAndTime_SetTimeManually: settingDefs.today,
    Settings_System_DateAndTime_SetDateManually: settingDefs.today,
    Settings_System_DateAndTime_SetTimeZoneManually: settingDefs.timezones[0],
    Settings_System_DateAndTime_SetTimeFormat: settingDefs.timeFormats[0],
    Settings_System_DateAndTime_SetDateFormat: settingDefs.dateFormats[0],
    Settings_System_Units_SpeedAndDistance: settingDefs.speedAndDistanceUnits[0],
    Settings_System_Units_Distance: settingDefs.distanceUnits[0],
    Settings_System_Units_Speed: settingDefs.speedUnits[0],
    Settings_System_Units_Charging: settingDefs.chargeSpeedUnits[0],
    Settings_System_Units_Temperature: settingDefs.temperatureUnits[0],
    Settings_System_Units_Pressure: settingDefs.pressureUnits[0],
    Settings_System_Units_Consumption: settingDefs.consumptionUnits[0],
    Settings_Display_CentralDisplay_Brightness: 1,
    Settings_Display_InstrumentCluster_Brightness: 1,
    Settings_Display_CentralDisplay_TouchTones: settingDefs.touchTones[2],
    Settings_Display_InstrumentCluster_ConfigureDisplayScope: settingDefs.displayScope.slice(),
    Settings_Display_InstrumentCluster_CustomisedView_Line1: settingDefs.displayCustomizedView[0],
    Settings_Display_InstrumentCluster_CustomisedView_Line2: settingDefs.displayCustomizedView[9],
    Settings_Display_InstrumentCluster_CustomisedView_Line3: settingDefs.displayCustomizedView[2],
    Settings_Display_InstrumentCluster_CustomisedView_Line4: settingDefs.displayCustomizedView[1],
    Settings_Display_CenterConsoleOperatingPanel_Brightness: 1,
    Settings_Display_AdditionalInstrument_Brightness: 1,
    Settings_Display_CenterConsoleOperatingPanel_TouchTones: settingDefs.touchTones[2],
    Settings_Display_CenterConsoleOperatingPanel_HapticFeedback: settingDefs.hapticFeedback[2],
    Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed: settingDefs.focusMovementSpeed[1],
    Settings_Display_InstrumentCluster_TrafficSignRecognition_Deviation: settingDefs.kmhToMs(9),
    Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit1: Object.freeze([ false, settingDefs.kmhToMs(130) ]),
    Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit2: Object.freeze([ false, settingDefs.kmhToMs(90) ]),
    Settings_Display_HUD_TrafficSign_Deviation: settingDefs.kmhToMs(9),
    Settings_Display_HUD_View: settingDefs.hudView.slice(),
    Settings_Display_HUD_DayAndNightView: settingDefs.hudDayNightView[0],
    Settings_Display_HUD_TurnOnDisplay: false,
    Settings_Display_HUD_IncomingCalls: settingDefs.hudIncomingCalls[0],
    Settings_Display_HUD_TrafficSign_ShowInHUD: false,
    Settings_Display_HUD_TrafficSign_SpeedingWarning: false,
    Settings_Display_HUD_Custom_Adjust_MainArea: settingDefs.hudCustomAdjustMainArea[1],
    Settings_Display_HUD_Custom_Adjust_StatusBar: true,
    Settings_Display_PassengerDisplay_TurnOnDisplay: true,
    Settings_Phone_PhoneBookSettings_Criteria: settingDefs.phoneBookSortingCriteria[0],
    Settings_Display_InstrumentCluster_HazardousSituations: false,
    Settings_Display_InstrumentCluster_TrafficSignRecognition_Warn: false,
    Settings_DeviceManager_BluetoothSettings_BT: true,
    Settings_DeviceManager_WiFiSettings_WiFi: false,
    Settings_DeviceManager_WiFiSettings_EnableInternetAccess: false,
    Settings_Volume_NavAnnouncements: 2,
    Settings_Volume_ParkAssistVolume: 4,
    Settings_Volume_AdjustWhenParkAssistActive: -1,
    Settings_Volume_AdjustToRoadNoise: 1,
    Settings_Volume_LaneDeparture: 2,
    Settings_Volume_Ringtone: 5,
    Settings_Volume_Message: false,
    Settings_Volume_TouchTones: settingDefs.touchTones[2],
    Settings_Vehicle_ManualCleaningPositionSpoiler: false,
    Settings_Vehicle_ParkingBrake: false,
    Settings_Display_AdditionalInstrument_Clock: false,
    Settings_Display_AdditionalInstrument_Illumination: false,
    Settings_Display_AdditionalInstrument_Compass: false,
    Settings_VoiceControl_HeyPorsche: true,
    Settings_VoiceControl_ShortDialogs1: true,
    Settings_VoiceControl_InterruptVoiceControl1: true,
    Settings_VoiceControl_OnlineVoiceRecognition: true,
    Settings_Radio_TrafficNotices: true,
    Settings_Radio_StationTracking: false,
    Settings_Radio_OnlineStationTracking: false,
    Settings_Radio_HDRadio: true,
    Settings_Radio_ShowProgram: true,
    Settings_Navigation_RouteOptions_DisplayRouteSelection: false,
    Settings_Navigation_RouteOptions_PorscheChargingPlaner: true,
    Settings_Navigation_RouteOptions_RecommendedBatteryChargeAtDestination: true,
    Settings_Navigation_RouteOptions_PorscheChargingService: false,
    Settings_Navigation_NavigationSettings_RangeWarning: false,
    Settings_Navigation_RouteOptions_ManualBatteryChargeAtDestination: 25,
    Settings_Navigation_RouteOptions_Avoid: [],
    Settings_Navigation_NavigationSettings_DisplayCountryInformation: true,
    Settings_Navigation_NavigationSettings_NavigationAnnouncements: 6,
    Settings_Volume_MuteNavigationDuringCalls: false,
    Settings_Navigation_NavigationSettings_DemoMode: false,
    Settings_Navigation_NavigationSettings_MapSettings_AutoZoom: false,
    Settings_Navigation_NavigationSettings_MapSettings_DisplayPois: false,
    Settings_Navigation_NavigationSettings_MapSettings_3DBuilding: false,
    Settings_Navigation_NavigationSettings_MapSettings_ShowSpeedLimits: false,
    Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView: settingDefs.navigationDayAndNightView[2],
    Settings_Navigation_NavigationSettings_TrafficInformation_Merging: true,
    Settings_Navigation_NavigationSettings_TrafficInformation_RailroadCrossing: true,
    Settings_NotificationCenter_Allow_Connectivity: true,
    Settings_NotificationCenter_Allow_Sms: true,
    Settings_NotificationCenter_Allow_Speech: true,
    Settings_NotificationCenter_Allow_Update: true,
    Settings_NotificationCenter_Calendar: true,
    Settings_NotificationCenter_Email: true,
    Settings_NotificationCenter_MissedCall: true,
    Settings_NotificationCenter_Service: true,
    Settings_NotificationCenter_ShowAll: true,
    Settings_NotificationCenter_SmartService: true,
    Settings_NotificationCenter_Sound: true,
    Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_ServiceInformation: true,
    Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_Updates: true,
    Settings_PrivacyAndPorscheConnect_PrivateMode: settingDefs.privacyModes[1],
    Settings_PrivacyAndPorscheConnect_ServicesWithoutTracking: settingDefs.privacyModes[0],
    Settings_PrivacyAndPorscheConnect_ServiceWithTracking: settingDefs.privacyModes[0],
    Settings_PrivacyAndPorscheConnect_ServicesWithTracking: settingDefs.privacyModes[0],
    Settings_PrivacyAndPorscheConnect_Improvements: settingDefs.privacyModes[0],
    Settings_PrivacyAndPorscheConnect_DataFromPorsche: settingDefs.privacyModes[0],
    Settings_PrivacyAndPorscheConnect_AnonData: settingDefs.privacyModes[0],
    Settings_Vehicle_VehicleLockingSystems_ComfortAccess: false,
    Settings_Vehicle_VehicleLockingSystems_FoldInMirrors: false,
    Settings_Vehicle_VehicleLockingSystems_LockWhileDriving: false,
    Settings_Vehicle_VehicleLockingSystems_TailgateComfortAccess: false,
    Settings_Vehicle_VehicleLockingSystems_AutoUnlock: false,
    Settings_Vehicle_VehicleLockingSystems_UnlockVicinity: false,
    Settings_Vehicle_VehicleLockingSystems_InteriorSurveillance: false,
    Settings_Vehicle_VehicleLockingSystems_AntiTheft: false,
    Settings_Vehicle_VehicleLockingSystems_DoorUnlocking: settingDefs.vehicleDoorUnlocking[1],
    // Settings_Vehicle_VehicleLockingSystems_AutoUnlock: settingDefs.vehicleAutoUnlock[0],
    Settings_Vehicle_TyrePressureMonitoring_Selection: settingDefs.vehicleTyrePressureSelection[0],
    Settings_Vehicle_TyrePressureMonitoring_FullLoad: false,
    Settings_Vehicle_TyrePressureMonitoring_ComfortPressure: false,
    Settings_Vehicle_LightAndVisibility_WiperWithRainSensor: false,
    Settings_Vehicle_LightAndVisibility_Interior_InteriorLightingLock: false,
    Settings_Vehicle_LightAndVisibility_Interior_AfterGlow: 20,
    Settings_Vehicle_LightAndVisibility_Exterior_AfterGlow: 30,
    Settings_Vehicle_LightAndVisibility_Interior_Brightness: 50,
    Settings_Vehicle_LightAndVisibility_Exterior_DippedBeam: false,
    Settings_Vehicle_LightAndVisibility_Exterior_BeamAdaption: false,
    Settings_Vehicle_LightAndVisibility_Exterior_DynamicHighBeam: false,
    Settings_Vehicle_LightAndVisibility_Reversing_WipeWhenReversing: false,
    Settings_Vehicle_LightAndVisibility_Reversing_LowerMirror: false,
    Settings_Vehicle_ShortcutButtons_SteeringWheel: settingDefs.vehicleShortcutButtons[0],
    Settings_Vehicle_ShortcutButtons_ClusterButton: settingDefs.vehicleShortcutButtons[0],
    Settings_Vehicle_AdditionalChassisSettings_RearAxle: false,
    Settings_Vehicle_AdditionalChassisSettings_ChassisAdaption: false,
    Settings_AssistanceSystems_ExteriorLights_BeamAdaption: false,
    Settings_AssistanceSystems_ExteriorLights_DynamicHighBeam: false,
    Settings_AssistanceSystems_TrafficSignRecognition_Cluster: false,
    Settings_AssistanceSystems_TrafficSignRecognition_Exceed: settingDefs.kmhToMs(5),
    Settings_AssistanceSystems_HazardousConditions_Cluster: false,
    Settings_AssistanceSystems_NightViewAssist_Contrast: 0,
    Settings_AssistanceSystems_NightViewAssist_WarningTime: settingDefs.assistanceWarningTime[1],
    Settings_AssistanceSystems_AdaptiveSpeedLimiter_ConsiderSpeedLimits: false,
    Settings_AssistanceSystems_AdaptiveSpeedLimiter_MaximumSpeed: settingDefs.kmhToMs(110),
    Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits: settingDefs.assistanceSpeedLimitsAdoption[1],
    Settings_AssistanceSystems_PorscheInnoDrive_ConsiderSpeedLimits: false,
    Settings_AssistanceSystems_PorscheInnoDrive_MaxSpeed: settingDefs.kmhToMs(110),
    Settings_AssistanceSystems_ParkAssist_VolumeReduction: 3,
    Settings_AssistanceSystems_ParkAssist_Volume: settingDefs.assistanceParkAssistVolume[1],
    Settings_AssistanceSystems_ParkAssist_AutoActivate: false,
    Settings_AssistanceSystems_WarnAndBrakeAssist_TurnAssist: false,
    Settings_AssistanceSystems_WarnAndBrakeAssist_SwerveAssist: false,
    Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning: settingDefs.assistanceCollisionWarning[1],
    Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning: settingDefs.assistanceDistanceWarning[1],
    Settings_AssistanceSystems_LaneDepartureWarning_Volume: settingDefs.assistanceLaneDepartureWarning[0],
    Settings_AssistanceSystems_LaneChangeAssist_Brightness: settingDefs.assistanceLaneChangeBrightness[0],
    Settings_SportChrono_MaxDelta: 1,
    Settings_SportChrono_Deviation: settingDefs.kmhToMs(5),
    Settings_SportChrono_ExtendedDataRecording: false,
    Settings_SportChrono_NewLap: true,
    Settings_SportChrono_DisplayPos: false,
    Settings_RangeDriveMode_Configuration_PredictiveConsumption_Title: false,
    Settings_Car_Trip_ConfigureTripPersonally_line1: settingDefs.tripPersonalConfig[16],
    Settings_Car_Trip_ConfigureTripPersonally_line2: settingDefs.tripPersonalConfig[17],
    Settings_Car_Trip_ConfigureTripPersonally_line3: settingDefs.tripPersonalConfig[18],
    Settings_Car_Trip_ConfigureTripPersonally_line4: settingDefs.tripPersonalConfig[13],
    Settings_Launcher_Context_Options_Detail: settingDefs.launcherContextOption[1],
    Settings_NotificationCenter_Allow: [ settingDefs.notificationCenterAllow[0] ],
    Calendar_Settings_StartWeek: settingDefs.calendarWeekStart[1],
    "Settings_Charging_ActivatePlugAndCharge-FirstLine": true,
    "Settings_Charging_BatteryConditionCharging-FirstLine": false,
    Settings_Media_AdjustSortingOfSources: settingDefs.mediaSources.slice()
  }),
  action: setPreferenceValueAction
): settingsState {
  switch (action.type) {

    case SET_PREFERENCE_VALUE:
    if (
      action.key === "Settings_System_DateAndTime_SetTimeManually" ||
      action.key === "Settings_System_DateAndTime_SetDateManually"
    ) {
      const new_value = new Date(action.value)
      return Object.assign(
        {},
        state,
        {
          Settings_System_DateAndTime_SetTimeManually: new_value,
          Settings_System_DateAndTime_SetDateManually: new_value
        }
      )
    } else if (action.key === "Settings_System_Units_SpeedAndDistance") {
      const index = settingDefs.speedAndDistanceUnits.indexOf(action.value)
      return Object.assign(
        {},
        state,
        {
          [action.key]: action.value,
          Settings_System_Units_Speed: settingDefs.speedUnits[index],
          Settings_System_Units_Distance: settingDefs.distanceUnits[index],
          Settings_System_Units_Charging: settingDefs.chargeSpeedUnits[index]
        }
      )
    } else {
      return Object.assign({}, state, { [action.key]: action.value })
    }

    default:
    return state
  }
}
