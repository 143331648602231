import i18n from "i18next"

import {
  MAKE_ROOM,
  CREATE_TIMER,
  EDIT_TIMER,
  TOGGLE_TIMER,
  REMOVE_TIMER,
  CREATE_PROFILE,
  EDIT_PROFILE,
  TOGGLE_PROFILE,
  REMOVE_PROFILE,
  EDIT_AUX_COOLING,
  ChargingScreenState,
  Action,
  Timer,
  Profile
} from "./types"
import settingDefs from "@pag/center/views/settingsScreen/definitions"

const includes = (array_of_objects: { id: object }[], object: { id: object }) => (
  array_of_objects.find((candidate) => candidate.id === object.id) !== undefined
)

// TIMER STATE

const defaultTimerState = {
  targetCharge: 85,
  charge: true,
  precool: true,
  temperature: 22,
  date: new Date("2021-02-03T08:00:00"),
  repeating: true,
  weekdays: []
}

function newTimer(): Timer {
  const state = Object.assign(Object.create(null), defaultTimerState)
  state.date = new Date(state.date)
  Object.defineProperty(state, "id", {
    value: Date.now(),
    enumerable: false
  })
  return Object.seal(state)
}

// PROFILE STATE

const defaultProfileState = {
  name: "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_label_NewProfile",
  address: undefined,
  minCharge: 85,
  optimized: true,
  start: new Date(0),
  end: (function () {
    const date = new Date(0)
    date.setUTCHours(6)
    return date
  }()),
}

// @NOTE(kirill): We use objects as IDs. React requires lists to have a unique string for its elements so we insert a
// poor man’s uuid into ID-object `valueOf` method. To get the string ID just convert it with String().

const createId = (function () {
  let count = -1
  return function () {
    count += 1
    const id = Object.create(null)
    const uuid = `profile-${count}`
    Object.defineProperty(id, "valueOf", {
      value: () => uuid,
      enumerable: false
    })
    return Object.freeze(id)
  }
}())

function newProfile(id?: object | undefined): Profile {
  const state = Object.assign(Object.create(null), defaultProfileState)
  Object.defineProperty(
    state,
    "id",
    {
      value: Object.freeze(id !== undefined ? id : createId())
    }
  )
  state.name = i18n.t(defaultProfileState.name)
  state.start = new Date(state.start)
  state.end = new Date(state.end)
  return Object.seal(state)
}

const generalProfile = newProfile()
generalProfile.name = "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_list_ProfileList_label_UniversalProfileLabel"

// @NOTE(kirill): Those should use proper translations but it should be done
// through dispatcher via language change event.

const workProfile = newProfile()
// workProfile.name = "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_list_label_BusinessTitle"
workProfile.name = "Work"

const homeProfile = newProfile()
// homeProfile.name = "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_list_label_HomeTitle"
homeProfile.name = "Home"

// @NOTE(kirill): The general profile isn’t removable so we have to expose its
// ID for later checks.

export const generalProfileId = generalProfile.id

// AUXILIARY COOLING STATE
const initialAuxCoolState = Object.freeze({
  precondition: settingDefs.auxiliaryCoolingPrecondition[0],
  temperature: 22,
  auxiliaryZones: [],
  remote: false
})

// ASSEMBLE CHARGING STATE

const initialChargingScreenState: ChargingScreenState = Object.create(null)

initialChargingScreenState.makeRoomForFullScreenComponent = false

initialChargingScreenState.timers = [ Object.freeze(newTimer()) ]
initialChargingScreenState.selectedTimers = initialChargingScreenState.timers.slice(0, 1)

initialChargingScreenState.profiles = [ Object.freeze(generalProfile), Object.freeze(workProfile), Object.freeze(homeProfile) ]
initialChargingScreenState.selectedProfiles = initialChargingScreenState.profiles.slice(0, 1)

Object.assign(
  initialChargingScreenState,
  initialAuxCoolState
)

export function chargingScreenReducer(
  state = initialChargingScreenState,
  action: Action
): ChargingScreenState {
  switch (action.type) {

    case MAKE_ROOM:
    return {
      ...state,
      makeRoomForFullScreenComponent: action.value
    }

    case CREATE_TIMER:
    return {
      ...state,
      timers: state.timers.concat([ Object.freeze(newTimer()) ])
    }

    case TOGGLE_TIMER:
    if (state.selectedTimers.includes(action.timer)) {
      return {
        ...state,
        selectedTimers: state.selectedTimers.filter((timer: Timer) => timer !== action.timer)
      }
    } else {
      return {
        ...state,
        selectedTimers: state.selectedTimers.concat([ action.timer ])
      }
    }

    case REMOVE_TIMER:
    return {
      ...state,
      timers: state.timers.filter((timer: Timer) => timer !== action.timer)
    }

    case EDIT_TIMER:
    // @ts-ignore
    const stale = state.timers.find((timer: Timer) => timer === action.timer)
    if (stale === undefined) {
      return state
    }

    const edited = Object.keys(stale).reduce(
      function (timer: Timer, key: string) {
        timer[key] = (
          action.data[key] !== undefined
          ? action.data[key]
          : stale[key]
        )
        return timer
      },
      newTimer()
    )
    Object.freeze(edited)

    const list = state.timers.slice()
    list.splice(list.indexOf(stale), 1, edited)

    let selected
    if (state.selectedTimers.includes(stale)) {
      selected = state.selectedTimers.slice()
      selected.splice(selected.indexOf(stale), 1, edited)
    }

    return {
      ...state,
      timers: list,
      selectedTimers: (
        selected === undefined
        ? state.selectedTimers
        : selected
      )
    }

    case CREATE_PROFILE:
    return {
      ...state,
      profiles: state.profiles.concat([ Object.freeze(newProfile()) ])
    }

    case TOGGLE_PROFILE:
    if (includes(state.selectedProfiles, action.profile)) {
      return {
        ...state,
        selectedProfiles: state.selectedProfiles.filter((profile: Profile) => profile.id !== action.profile.id)
      }
    } else {
      return {
        ...state,
        selectedProfiles: state.selectedProfiles.concat([ action.profile ])
      }
    }

    case REMOVE_PROFILE:
      return {
        ...state,
        profiles: state.profiles.filter((profile: Profile) => profile.id !== action.profile.id)
      }

    case EDIT_PROFILE:
    // @ts-ignore
    const stale_profile = state.profiles.find((profile: Profile) => profile.id === action.profile.id)
    if (stale_profile === undefined) {
      return state
    }

    const edited_profile = Object.keys(stale_profile).reduce(
      function (profile: Profile, key: string) {
        profile[key] = (
          action.data[key] !== undefined
          ? action.data[key]
          : stale_profile[key]
        )
        return profile
      },
      newProfile(action.profile.id)
    )
    Object.freeze(edited_profile)

    const new_profile_list = state.profiles.slice()
    new_profile_list.splice(new_profile_list.indexOf(stale_profile), 1, edited_profile)

    let new_selected_profiles
    if (state.selectedProfiles.includes(stale_profile)) {
      new_selected_profiles = state.selectedProfiles.slice()
      new_selected_profiles.splice(new_selected_profiles.indexOf(stale_profile), 1, edited_profile)
    }

    return {
      ...state,
      profiles: new_profile_list,
      selectedProfiles: (
        new_selected_profiles === undefined
        ? state.selectedProfiles
        : new_selected_profiles
      )
    }

    case EDIT_AUX_COOLING:
    return Object.keys(initialAuxCoolState).reduce(
      function (result, key) {
        if (action.data[key] !== undefined) {
          result[key] = action.data[key]
        }
        return result
      },
      Object.assign(Object.create(null), state)
    )

    default:
    return state
  }
}
