import React, { useState } from "react"
import useReactRouter from "use-react-router"
import cx from "classnames"

import Icon from "@pag/center/components/icon/Icon"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"
import { ROUTES } from "@pag/center/views/routes"
import { IconType } from "@pag/center/components/icon/iconType"

import "./tile.scss"

interface ITile {
  text: string
  iconType: IconType
  isSmall: boolean
  url?: string
  isPassengerScreen?: boolean
  className?: string
  disabled?: boolean
  pressed?: boolean
  setVisibleList?: (list: any) => void
  settingsList?: any
  count?: number
  callback?: () => void
}

const Tile: React.FunctionComponent<ITile> = (props) => {
  const [pressed, setPressed] = useState(props.pressed)
  const url = props.url ? props.url : "/"
  const { history } = useReactRouter()
  return (
    <label
      className={cx(
        "tile",
        "state-focusable",
        { "state-pressed": pressed },
        { "tile--small": props.isSmall },
        { "state-disabled": props.disabled },
        props.className
      )}
      onClick={() => {
        if (!props.disabled) {
          if (!url || url === "/") {
            setPressed(true)
            setTimeout(() => {
              setPressed(false)
            }, 500)
          } else {
            history.push(url)
            if (!props.isPassengerScreen) {
              history.push(url)
              // @ts-ignore
              props.updateLocationHistory(url, "center")
            }
          }
          if (props.settingsList) {
            history.push(ROUTES.SETTINGS)
            props.setVisibleList!(props.settingsList)
          }
          if (props.callback) {
            props.callback()
          }
        }
      }}
    >
      <div className="tile__icon-wrapper">
        {/* <div className="animated-bg--growing-circle" /> */}
        <div className="animated-bg--pressed" />
        <Icon iconType={props.iconType} iconClass="tile__icon" />
        {props.count && <div className="tile__count">{props.count}</div>}
      </div>
      <div className="tile__text">
        <I18nLabel text={props.text} />
      </div>
    </label>
  )
}

export default Tile
