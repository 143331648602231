export const UPDATE_PHONE_SEARCH = "UPDATE_PHONE_SEARCH"
export const SET_BREAKDOWN_CALL = "SET_BREAKDOWN_CALL"
export const SET_ONGOING_CALL = "SET_ONGOING_CALL"

export type PhoneNumberKind = "mobile" | "private"

export type Contact = {
  firstName: string
  lastName?: string
  mobile: string
  private?: string
}

export type UnknownNumber = {
  number: string
}

export type KnownNumber = {
  contact: Contact
  kind: PhoneNumberKind
}

export type PhoneNumber = UnknownNumber | KnownNumber;

export type Call = {
  kind: "incoming" | "outgoing" | "missed"
  number: PhoneNumber
  date: Date
}

export type UpdatePhoneSearchAction = {
  type: typeof UPDATE_PHONE_SEARCH
  active: boolean
}

export type SetBreakdownCallAction = {
  type: typeof SET_BREAKDOWN_CALL
  active: boolean
}

export type SetOngoingCall = {
  type: typeof SET_ONGOING_CALL
  ongoing: PhoneNumber
}
