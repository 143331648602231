import React, { FC } from "react"

import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"

const tires = [
  {
    position: [ "front", "left" ],
    pressure: 190000
  },
  {
    position: [ "front", "right" ],
    pressure: 220000
  },
  {
    position: [ "rear", "left" ],
    pressure: 210000
  },
  {
    position: [ "rear", "right" ],
    pressure: 220000
  }
];

interface ITyrePressureDatailScreen {
  pressure: any
}

const TyrePressureDetailScreen: FC<ITyrePressureDatailScreen> = ({ pressure }) => {
  return (
    <div className="content__tyre--pressure">
      {tires.map((tire) => (
        <span
          key={tire.position.join("-")}
          className={"content__value--position " + tire.position.map((pos) => `content__value--position-${pos}`).join(" ")}
        >
         {pressure.formatter(tire.pressure)}
        </span>
      ))}
      {<I18nLabel className="content__unit" text={pressure.label} />}
    </div>
  )
}

export default TyrePressureDetailScreen
