import { MediaSoundState, BalanceAndFaderType, SET_BALANCE, SET_FADE, SET_BASS, SET_TREBLE, BassAndTrebleType } from "./types"

const initialStateBalanceAndFader: MediaSoundState = {
  balance: 0,
  fade: 0,
  bass: 0,
  treble: 0
}

export function mediaSoundReducer(
  state = initialStateBalanceAndFader,
  action: BalanceAndFaderType | BassAndTrebleType
): MediaSoundState {
  switch (action.type) {
    case SET_BALANCE:
      return {
        ...state,
        balance: action.payload
      }
    case SET_FADE:
      return {
        ...state,
        fade: action.payload
      }
    case SET_BASS:
      return {
        ...state,
        bass: action.payload
      }
    case SET_TREBLE:
      return {
        ...state,
        treble: action.payload
      }
    default:
      return state
  }
}
