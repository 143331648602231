import { IconType } from "@pag/center/components/icon/iconType"

export const UPDATE_LAUNCHER_BIG_TILE_ORDER = "UPDATE_LAUNCHER_BIG_TILE_ORDER"
export const UPDATE_LAUNCHER_SMALL_TILE_ORDER = "UPDATE_LAUNCHER_SMALL_TILE_ORDER"

export type LauncherTileState = {
  bigTiles: LauncherTile[]
  smallTiles: LauncherTile[]
}

export type LauncherTile = {
  text: string
  url?: string
  iconType: IconType
  settings_list?: any
  count?: number
}

export type updateLauncherBigTileOrderAction = {
  type: typeof UPDATE_LAUNCHER_BIG_TILE_ORDER
  payload: {
    tiles: LauncherTile[]
  }
}

export type updateLauncherSmallTileOrderAction = {
  type: typeof UPDATE_LAUNCHER_SMALL_TILE_ORDER
  payload: {
    tiles: LauncherTile[]
  }
}

export type LauncherTileActionType = updateLauncherSmallTileOrderAction | updateLauncherBigTileOrderAction
