import { Trace, AlternativeRouteInfo } from "../types"
import { DriveMode, TravelDataSocState, NaviClimateSetting } from "@pag/center/views/navigationScreen/mapMain/types"
import { PoiItem } from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/types"
import { IconType } from "@pag/center/components/icon/iconType"
import mapboxgl, { LngLatBounds, LngLatLike } from "mapbox-gl"

/**
 * record traces with porsche-carui--drive-recorder
 */
export const traceJson1: Trace[] = require("./trace_log_200.json")
export const traceJson2: Trace[] = require("./trace_log_2_200.json")
export const traceJson3: Trace[] = require("./trace_log_3_200.json")

// Get data from initial-navigation-event (initNavigationAction) in NavigationService
export const initialPosition: LngLatLike = [8.569278999994594, 50.09860599995537]
// Get data from getCenter(), getZoom() and getBounds() in NewMap
export const overview: { zoom: number, center: LngLatLike } = {
  zoom: 12.05,
  center: [8.546010550193046, 50.102840687163734]
}
const bounds: LngLatBounds = new mapboxgl.LngLatBounds(
  [8.247725022196903, 49.80105859059648],
  [8.987459415355886, 50.4658137337112]
)

/**
 * coordinates are used to retrive the right data from MapBoxAPI - important has to be as many as needed to get allways the same route back.
 */
export const alternativeRoutesInfo: AlternativeRouteInfo[] = [
  {
    id: "111",
    coordinates: [
      [8.569279, 50.09860599999999],
      [8.654758077000224, 50.10707560754338]
    ],
    bounds,
    trace: traceJson1,
    driveMode: DriveMode.Sport,
    soc: {
      value: 65,
      state: TravelDataSocState.Available
    }
  },
  {
    id: "222",
    coordinates: [
      [8.569279, 50.09860599999999],
      [8.591146, 50.096456],
      [8.59876, 50.09655],
      [8.654758077000224, 50.10707560754338]
    ],
    bounds,
    trace: traceJson2,
    driveMode: DriveMode.Normal,
    soc: {
      value: 60,
      state: TravelDataSocState.Available
    },
    climateSetting: NaviClimateSetting.Eco
  },
  {
    id: "333",
    coordinates: [
      [8.569279, 50.09860599999999],
      [8.621464, 50.107869],
      [8.652247, 50.110624],
      [8.654758077000224, 50.10707560754338]
    ],
    bounds,
    trace: traceJson3,
    driveMode: DriveMode.Individual,
    soc: {
      value: 55,
      state: TravelDataSocState.Available
    }
  }
]

export const poiStackList: PoiItem[] = [
  {
    location: {
      addressLineFirst: "Mainzer Landstr. 693",
      addressLineSecond: "65934 Frankfurt am Main",
      addressLineThird: "Deutschland",
      coordinates: [8.582306, 50.099682]
    },
    // distance: 0,
    icon: IconType.icon_parking
  },
  {
    location: {
      addressLineFirst: "Mainzer Landstr. 545",
      addressLineSecond: "65933 Frankfurt am Main",
      addressLineThird: "Deutschland",
      coordinates: [8.604852, 50.099988]
    },
    // distance: 0,
    icon: IconType.icon_charging_station
  },
  {
    location: {
      addressLineFirst: "Westend Süd",
      addressLineSecond: "60327 Frankfurt am Main",
      addressLineThird: "Deutschland",
      coordinates: [8.644844, 50.1105916]
    },
    // distance: 0,
    icon: IconType.icon_city_events
  }
]
