import { connect } from "react-redux"

import { AppState } from "../../../../redux/reducer"
import TyrePressureDetailScreen from "./TyrePressureDetailScreen"

const mapStateToProps = (state: AppState) => ({
  pressure: state.settings.Settings_System_Units_Pressure
})

export default connect(mapStateToProps, undefined)(TyrePressureDetailScreen)

