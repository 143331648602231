import { Trace, AlternativeRouteInfo } from "../types"
import { DriveMode, TravelDataSocState, NaviClimateSetting } from "@pag/center/views/navigationScreen/mapMain/types"
import { PoiItem } from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/types"
import { IconType } from "@pag/center/components/icon/iconType"
import mapboxgl, { LngLatBounds, LngLatLike } from "mapbox-gl"

/**
 * record traces with porsche-carui--drive-recorder
 */
export const traceJson1: Trace[] = require("./trace_log_1.json")
export const traceJson2: Trace[] = require("./trace_log_2.json")
export const traceJson3: Trace[] = require("./trace_log_3.json")

// Get data from initial-navigation-event (initNavigationAction) in NavigationService
export const initialPosition: LngLatLike = [9.164547999960405, 48.770973000003835]
export const initialBearing: number = 64.2445556105929

// Get data from getCenter(), getZoom() and getBounds() in NewMap
export const overview: { zoom: number, center: LngLatLike } = {
  zoom: 11.8,
  center: [9.149607580300568, 48.77141089997403]
  // center: [9.097861887937825, 48.79153905491938] // only used for alternative Routes
}
const bounds: LngLatBounds = new mapboxgl.LngLatBounds(
  [8.838994179959371, 48.67932013371444],
  [9.460220980646938, 48.863333067974025]
)

/**
 * coordinates are used to retrive the right data from MapBoxAPI - important has to be as many as needed to get allways the same route back.
 */
export const alternativeRoutesInfo: AlternativeRouteInfo[] = [
  {
    id: "111",
    coordinates: [
      [9.164571, 48.770943],
      [9.171854, 48.775193],
      [9.152722, 48.834103]
    ],
    bounds,
    trace: traceJson1,
    driveMode: DriveMode.Sport,
    soc: {
      value: 60,
      state: TravelDataSocState.Available
    }
  },
  {
    id: "222",
    coordinates: [
      [9.164571, 48.770943],
      [9.171854, 48.775193],
      [9.172897, 48.811773],
      [9.152722, 48.834103]
    ],
    bounds,
    trace: traceJson2,
    driveMode: DriveMode.Normal,
    soc: {
      value: 61,
      state: TravelDataSocState.Available
    },
    climateSetting: NaviClimateSetting.Eco
  },
  {
    id: "333",
    coordinates: [
      [9.164571, 48.770943],
      [9.184618, 48.778185],
      [9.181567, 48.792544],
      [9.152722, 48.834103]
    ],
    bounds,
    trace: traceJson3,
    driveMode: DriveMode.Individual,
    soc: {
      value: 55,
      state: TravelDataSocState.Available
    }
  }
]

export const poiStackList: PoiItem[] = [
  // {
  //   location: {
  //     addressLineFirst: "Porscheplatz 1",
  //     addressLineSecond: "70435 Stuttgart",
  //     addressLineThird: "Deutschland",
  //     coordinates: [9.152118, 48.833949]
  //   },
  //   // distance: 0,
  //   icon: IconType.icon_parking
  // },
  {
    location: {
      addressLineFirst: "Kleiner Schloßplatz 11",
      addressLineSecond: "70173 Stuttgart",
      addressLineThird: "Deutschland",
      coordinates: [9.176183, 48.778533]
    },
    // distance: 0,
    icon: IconType.icon_parking
  },
  {
    location: {
      addressLineFirst: "Porscheplatz 9",
      addressLineSecond: "70435 Stuttgart",
      addressLineThird: "Deutschland",
      coordinates: [9.151311, 48.834169]
    },
    // distance: 0,
    icon: IconType.icon_charging_station
  },

  {
    location: {
      addressLineFirst: "Schwieberdinger Str. 198",
      addressLineSecond: "70435 Stuttgart",
      addressLineThird: "Deutschland",
      coordinates: [9.141429, 48.840573]
    },
    // distance: 0,
    icon: IconType.icon_dls_top
  }
]
