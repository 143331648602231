export const TOGGLE_MEDIA_PLAYVIEW = "TOGGLE_MEDIA_PLAYVIEW"
export const TOGGLE_APPLEID_POPUP = "TOGGLE_APPLEID_POPUP"
export const TOGGLE_LYRICS_DUMMY = "TOGGLE_LYRICS_DUMMY"
export const TOGGLE_OPTIONS_SLIDEOUT = "TOGGLE_OPTIONS_SLIDEOUT"
export const TOGGLE_SIRIUS_XM_POPUP = "TOGGLE_SIRIUS_XM_POPUP"
export const SHOW_SIRIUS_XM_WELCOMESCREEN = "SHOW_SIRIUS_XM_WELCOMESCREEN"
export const SHOW_APPLE_CARPLAY = "SHOW_APPLE_CARPLAY"
export const SHOW_ANDROID_AUTO = "SHOW_ANDROID_AUTO"

export type MediaState = {
  isPlayviewVisible: boolean
  appleIdPopUpVisible: boolean
  isLyricsDummyVisible: boolean
  isOptionsSlideOutVisible: boolean
  siriusXmPopUpVisible: boolean
  siriusXmWelcomeScreenVisible: boolean
  showAppleCarplay: boolean
  showAndroidAuto: boolean
  availableSources: any[]
}

export type toggleMediaPlayViewAction = {
  type: typeof TOGGLE_MEDIA_PLAYVIEW
}

export type toggleAppleIDPopUpAction = {
  type: typeof TOGGLE_APPLEID_POPUP
  visible: boolean
}

export type toggleLyricsDummyAction = {
  type: typeof TOGGLE_LYRICS_DUMMY
}

export type toggleOptionsSlideoutAction = {
  type: typeof TOGGLE_OPTIONS_SLIDEOUT
}

export type toggleSiriusXMPopUpAction = {
  type: typeof TOGGLE_SIRIUS_XM_POPUP
  visible: boolean
}

export type toggleSiriusXMPWelcomeScreenAction = {
  type: typeof SHOW_SIRIUS_XM_WELCOMESCREEN
  visible: boolean
}

export type showAppleCarplayAction = {
  type: typeof SHOW_APPLE_CARPLAY
  show: boolean
}

export type showAndroidAutoAction = {
  type: typeof SHOW_ANDROID_AUTO
  show: boolean
}

export type Action =
  | toggleMediaPlayViewAction
  | toggleAppleIDPopUpAction
  | toggleLyricsDummyAction
  | toggleOptionsSlideoutAction
  | toggleSiriusXMPopUpAction
  | toggleSiriusXMPWelcomeScreenAction
  | showAppleCarplayAction
  | showAndroidAutoAction
