import React, { useEffect, useState } from "react"
import ClassNames from "classnames"

import useUnitFormat from "@pag/utils/hooks/useUnitFormat"
import { ManeuverType } from "@pag/center/views/navigationScreen/mapMain/types"
import Icon from "@pag/center/components/icon/Icon"
import DistanceDestination from "@pag/center/views/navigationScreen/mapMain/distanceDestination/DistanceDestination"
import { IconType } from "@pag/center/components/icon/iconType"

import "./routeGuidanceInfo.scss"

// TODO: add types
interface IRouteGuidanceInfoProps {
  currentPosition: [number, number]
  maneuver: ManeuverType
  followEvent: object | undefined
  laneGuidance: []
  className?: string
}

const RouteGuidanceInfo: React.FC<IRouteGuidanceInfoProps> = ({
  maneuver,
  laneGuidance,
  currentPosition,
  ...props
}) => {
  const [distanceFromCarToManeuver, setDistanceFromCarToManeuver] = useState(0)
  const formatDistance = useUnitFormat("distance")
  // const roundAboutType = undefined
  // const stopoverIdx = -1

  useEffect(() => {
    if (maneuver && maneuver.location) {
      const distance = distanceInMeterBetweenEarthCoordinates(
        currentPosition[1],
        currentPosition[0],
        maneuver.location[1],
        maneuver.location[0]
      )
      setDistanceFromCarToManeuver(Math.round(distance))
    }
  }, [currentPosition, maneuver, setDistanceFromCarToManeuver])

  /**
   * icon-04_00_p_TurnArrow_Fork40 => left-Fork
   * icon-04_00_p_TurnArrow_StandardTurnC0 => right-turn
   * icon-04_00_p_TurnArrow_StandardTurn40 => left-turn
   * icon-04_00_p_TurnArrow_StandardTurn00 => straight
   */

  function renderManeuverIcon() {
    // if (roundAboutType && followEvent && followEvent.showManeuver) {
    //   return <Icon iconClass="maneuver__icon--roundabout-base-icon" iconType="icon_04_base_layer_RoundAbout" />
    // }
    // const icon =
    //   followEvent && !followEvent.showManeuver ? "icon-04_00_p_TurnArrow_StandardTurn00" : maneuver.maneuverIcon
    // return <Icon iconClass="maneuver__icon" iconType={icon} />
    let icon = "icon-04_00_p_TurnArrow_StandardTurn00"
    if (maneuver) {
      if (maneuver.modifier === "left") {
        icon = IconType.icon_04_00_p_TurnArrow_StandardTurn40
      } else if (maneuver.modifier === "right") {
        icon = IconType.icon_04_00_p_TurnArrow_StandardTurnC0
      } else if (maneuver.modifier === "slight left") {
        icon = IconType.icon_04_00_p_TurnArrow_StandardTurn20
      } else if (maneuver.modifier === "slight right") {
        icon = IconType.icon_04_00_p_TurnArrow_StandardTurnE0
      } else if (maneuver.modifier === "uturn") {
        icon = IconType.icon_04_00_p_TurnArrow_StandardTurn60
      } else if (maneuver.type === "arrive" && maneuver.modifier === "destination") {
        icon = IconType.icon_destination_flag
      }
    }
    return <Icon iconClass="maneuver__icon" iconType={icon} />
  }
  function renderStopoverNumber() {
    //   if (followEvent && followEvent.showManeuver && stopoverIdx > 0 ?) {
    //       return <span className="maneuver__stopover-number">{stopoverIdx}</span>
    //   }
    return null
  }

  return (
    <div className={ClassNames("route-guidance-info-wrapper", props.className)}>
      {maneuver ? (
        <div className="maneuver">
          <div className="maneuver__guide">
            <div className="maneuver__icon-wrapper">{renderManeuverIcon()}</div>
            {renderStopoverNumber()}
            <div className="maneuver__distance-to-maneuver">
              <DistanceDestination formattedDistance={formatDistance(distanceFromCarToManeuver)} />
            </div>
          </div>
          {/**
                   <pag3-icon *ngIf="followEvent?.showManeuver && roadIcon"
               class="maneuver__icon--additional"
               [url]="roadIcon"></pag3-icon>
                   <pag3-i18n-label *ngIf="followEvent?.showManeuver && cardinalDirection"
                     id="CardinalDirection"
                     class="maneuver__direction maneuver__cardinal-direction"
                     ttIgnore
                     [nonI18nValue]="cardinalDirection"></pag3-i18n-label>
    <pag3-non-i18n-label *ngIf="followEvent?.showManeuver"
                         class="maneuver__direction maneuver__direction-info"
                         [nonI18nValue]="maneuver?.event.title"></pag3-non-i18n-label>
               */}
        </div>
      ) : null}
      {/* {laneGuidance && laneGuidance.length ? (
        <div className="maneuver tool-tip tool-tip--second-maneuver">
          <LaneGuidance laneGuidance={laneGuidance} />
        </div>
      ) : null} */}
    </div>
  )
}

export default RouteGuidanceInfo

// TODO metrics
function degreesToRadians(degrees: any) {
  return (degrees * Math.PI) / 180
}

function distanceInMeterBetweenEarthCoordinates(lat1: number, lon1: number, lat2: number, lon2: number) {
  var earthRadiusKm = 6371

  var dLat = degreesToRadians(lat2 - lat1)
  var dLon = degreesToRadians(lon2 - lon1)

  lat1 = degreesToRadians(lat1)
  lat2 = degreesToRadians(lat2)

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadiusKm * c * 1000
}
