import {
  Action,
  audioPlayerState,
  SET_SONG,
  SET_SONG_NEXT,
  SET_SONG_PREV,
  song,
  PLAY_SONG,
  SET_CURRENT_BROWSING_CATEGORY_USB,
  DISABLE_ENTRY_ANIMATIONS,
  ADD_MEDIA_FAVORITE,
  TOGGLE_MEDIA_FAVORITE,
  SET_CURRENT_BROWSING_CATEGORY_APPLE,
  SET_CURRENT_BROWSING_CATEGORY_APPLE_PODCASTS,
  TRIGGER_LIST_SWITCH_ANIMATION,
  SET_CURRENT_BROWSING_CATEGORY_SIRIUS_XM
} from "@pag/center/components/player/audioPlayer/types"
import { ESourceNames } from "@pag/center/components/quickFilter/quickFilterIconWithSpeaker/types"
import {
  songListRadio,
  songListUSB,
  songListOnlineRadio,
  podcasts,
  favourtiesRadios
} from "@pag/center/components/player/audioPlayer/helper"

const initialState: audioPlayerState = {
  [ESourceNames.USB]: {
    songs: songListUSB,
    selected: songListUSB[0]
  },
  [ESourceNames.BLUETOOTH]: {
    songs: songListUSB,
    selected: songListUSB[0]
  },
  [ESourceNames.FAVORITES]: {
    songs: favourtiesRadios,
    selected: favourtiesRadios[0]
  },
  [ESourceNames.RADIO]: {
    songs: songListRadio,
    selected: songListRadio[0]
  },
  [ESourceNames.ONLINE_RADIO]: {
    songs: songListOnlineRadio,
    selected: songListOnlineRadio[0]
  },
  [ESourceNames.APPLE_PODCASTS]: {
    songs: podcasts,
    selected: podcasts[0]
  },
  [ESourceNames.APPLE_MUSIC]: {
    songs: songListUSB,
    selected: songListUSB[0]
  },
  [ESourceNames.APPLE_CARPLAY]: {
    songs: songListUSB.slice(0, 1),
    selected: songListUSB[0]
  },
  [ESourceNames.ANDROID_AUTO]: {
    songs: songListUSB.slice(0, 1),
    selected: songListUSB[0]
  },
  playing: false,
  currentBrowsingCategory_USB: { name: "Overview", categoryType: "Overview", label: "Overview" },
  currentBrowsingCategory_Apple: { name: "Overview_Apple_Music", categoryType: "Overview", label: "Overview" },
  currentBrowsingCategory_ApplePodcasts: {
    name: "Overview_Apple_Podcasts",
    categoryType: "Overview",
    label: "Overview"
  },
  currentBrowsingCategory_SiriusXM: { name: "Overview_Sirius_XM", categoryType: "Overview", label: "Overview" },
  entryAnimationsDisabled: false,
  listFilter: "",
  listSwitchAnimation: false
}

export function songReducer(
  state = initialState,
  action: Action
): audioPlayerState {
  switch (action.type) {
    case ADD_MEDIA_FAVORITE:
      if (state[ESourceNames.FAVORITES].songs.includes(action.favorite)) {
        return { ...state }
      } else {
        return {
          ...state,
          [ESourceNames.FAVORITES]: {
            ...state[ESourceNames.FAVORITES],
            songs: [...state[ESourceNames.FAVORITES].songs, action.favorite],
            selected: action.favorite
          }
        }
      }

    case TOGGLE_MEDIA_FAVORITE:
      if (state[ESourceNames.FAVORITES].songs.includes(action.favorite)) {
        return {
          ...state,
          [ESourceNames.FAVORITES]: {
            ...state[ESourceNames.FAVORITES],
            songs: state[ESourceNames.FAVORITES].songs.filter((song: song) => song !== action.favorite)
          }
        }
      } else {
        return {
          ...state,
          [ESourceNames.FAVORITES]: {
            ...state[ESourceNames.FAVORITES],
            songs: [...state[ESourceNames.FAVORITES].songs, action.favorite],
          }
        }
      }

    case SET_SONG:
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          selected: state[action.source].songs.find((song: song) => action.song === song) || state[action.source].songs[0],
        },
        playing: action.playing,
        listFilter: action.listFilter
      }

    case SET_SONG_NEXT: {
      const list = state[action.source].songs
      const nextIndex = list.indexOf(state[action.source].selected) + 1
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          selected: list[nextIndex] || list[0],
        },
        playing: action.playing,
        listFilter: action.listFilter
      }
    }

    case SET_SONG_PREV: {
      const list = state[action.source].songs
      const prevIndex = list.indexOf(state[action.source].selected) - 1
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          selected: list[prevIndex] || list[list.length - 1],
        },
        playing: action.playing,
        listFilter: action.listFilter
      }
    }

    case PLAY_SONG:
      return {
        ...state,
        [action.source]: {
          songs: state[action.source].songs,
          selected: action.song
        },
        playing: action.playing
      }

    case SET_CURRENT_BROWSING_CATEGORY_USB:
      return { ...state, currentBrowsingCategory_USB: action.category }

    case SET_CURRENT_BROWSING_CATEGORY_APPLE:
      return { ...state, currentBrowsingCategory_Apple: action.category }

    case SET_CURRENT_BROWSING_CATEGORY_APPLE_PODCASTS:
      return { ...state, currentBrowsingCategory_ApplePodcasts: action.category }

    case SET_CURRENT_BROWSING_CATEGORY_SIRIUS_XM:
      return { ...state, currentBrowsingCategory_SiriusXM: action.category }

    case DISABLE_ENTRY_ANIMATIONS:
      return { ...state, entryAnimationsDisabled: action.disabled }

    case TRIGGER_LIST_SWITCH_ANIMATION:
      return { ...state, listSwitchAnimation: action.animation }

    default:
      return state
  }
}
