import React, { useRef, useEffect, useState } from "react"
import cx from "classnames"

import ScrollPositionIndicatorPixel from "./scrollPositionIndicatorPixel/ScrollPositionIndicatorPixel"

import "./list.scss"

interface IList {
  header?: any
  content: any
  footer?: any
  scrollSnapProximity?: boolean
  isScrollbarVisible?: boolean
  className?: string
  headerOutsideList?: boolean
  showAntiDoubleLineSpacer?: boolean
  disableScroll?: boolean
  isSlideOutList?: boolean
  resetScrollbarPosition?: boolean
  showAlfanumericScrollBar?: boolean
}

const List: React.FunctionComponent<IList> = ({
  header,
  content,
  footer,
  scrollSnapProximity,
  isScrollbarVisible,
  className,
  headerOutsideList,
  showAntiDoubleLineSpacer,
  disableScroll,
  isSlideOutList,
  resetScrollbarPosition,
  showAlfanumericScrollBar,
  children
}) => {
  const listContent = useRef<HTMLDivElement>(null)
  const listContainer = useRef<HTMLDivElement>(null)
  const [totalListHeight, setTotalListHeight] = useState(0)
  const [handleBarPos, sethandleBarPos] = useState(0)
  const [handleBarHeight, sethandleBarHeight] = useState(0)
  const [scrollbarVisible, setScrollbarVisible] = useState<boolean>(false)

  // here linter yells about `totalListHeight` not being listed as a dependency, that shouldn’t be a problem as its
  // value is set here based on `content` prop change
  // eslint-disable-next-line
  useEffect(() => {
    if (listContent.current && listContent.current) {
      if (totalListHeight !== listContent.current.scrollHeight) {
        setTotalListHeight(listContent.current.scrollHeight)
      }

      if (listContainer.current) {
        const scrollbarHeight = listContainer.current.clientHeight - 24 // account for padding
        const visibleListHeight = listContainer.current.clientHeight
        const handleBarCompleteHeight = Math.round((visibleListHeight / totalListHeight) * scrollbarHeight)
        sethandleBarHeight(handleBarCompleteHeight > 12 ? handleBarCompleteHeight : 12) // minimum size
      }
    }
  })

  useEffect(() => {
    if (isSlideOutList) {
      const timeoutID = setTimeout(() => setScrollbarVisible(true), 300)
      return () => clearTimeout(timeoutID)
    } else {
      setScrollbarVisible(true)
    }
  }, [isSlideOutList])

  // needed to fix scrollbar on playview slideout at media screen
  useEffect(() => {
    if (resetScrollbarPosition) {
      sethandleBarPos(0)
    }
  }, [resetScrollbarPosition])

  const showScrollPositionIndicatorPixel = () => {
    if (listContainer.current) {
      if (scrollbarVisible && totalListHeight > listContainer.current.clientHeight) {
        return <ScrollPositionIndicatorPixel handleBarPosition={handleBarPos} handleBarHeight={handleBarHeight} />
      } else if (showAlfanumericScrollBar) {
        return <ScrollPositionIndicatorPixel alfanumeric handleBarPosition={handleBarPos} handleBarHeight={handleBarHeight} />
      }
    }
  }

  const handleScroll = (event: React.UIEvent) => {
    const target = event.target as HTMLElement
    if (listContainer.current) {
      const scrollbarHeight = listContainer.current.clientHeight - 24 // account for padding
      const scrollPerCent = target.scrollTop / totalListHeight
      sethandleBarPos(scrollbarHeight * scrollPerCent)
    }
  }

  const showHeader = () => {
    if (header) {
      return <div className="list__list-header">{header}</div>
    }
  }

  return (
    <div className={cx("list", className, { "header-outside-list": headerOutsideList })}>
      {showHeader()}

      {/* 
            // the invisible-overlay-to-stop-click-through is duplicated, because this one up here
            //     blocks interaction with the header and the one inside the everything-but-static-header-container
            //     blocks interaction with other list items  */}

      <div onScroll={handleScroll} ref={listContainer} className="list__everything-but-static-header-container">
        <div
          className={cx(
            "list__container",
            { "list--scroll-snap-proximity": scrollSnapProximity },
            { "list__container--with-scroll-bar": scrollbarVisible },
            { "list__container--no-scrolling": disableScroll }
          )}
        >
          <div className="list__content-container">
            <div ref={listContent} className="list__content-container">
              {content}
            </div>
            <div className="solid-lines"></div>

            <span className="list__content-container"></span>

            <div className="solid-lines"></div>
            {footer}

            {showAntiDoubleLineSpacer ? (
              <div className="list__spaceholder-to-avoid-double-lines-with-last-item"></div>
            ) : null}
          </div>
        </div>

        {/* < !--normal scrollbar-- > */}

        {showScrollPositionIndicatorPixel()}
      </div>

      {children}
    </div>
  )
}

export default List
