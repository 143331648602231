import { Location } from "history"

export const UPDATE_LOCATION_HISTORY = "UPDATE_LOCATION_HISTORY"
export const SET_HOME_BUTTON_URL = "SET_HOME_BUTTON_URL"
export const SET_PAGE_TRANSITION_ANIMATION = "SET_PAGE_TRANSITION_ANIMATION"
export const SET_SOC = "SET_SOC"
export const SET_INCOMING_CALL_POPUP_VISIBILITY = "SET_INCOMING_CALL_POPUP_VISIBILITY"
export const SET_VOICE_SEARCH = "SET_VOICE_SEARCH"
export const SET_POPUP_VISIBLE = "SET_POPUP_VISIBLE"
export const SET_POPUP_HIDDEN = "SET_POPUP_HIDDEN"

export type LocationState = {
  location: Location
  source: string
}

export type UpdateLocationHistoryAction = {
  type: typeof UPDATE_LOCATION_HISTORY
  location: Location
  source: string
}

export type SetHomeButtonUrlAction = {
  type: typeof SET_HOME_BUTTON_URL
  url: string
}

export type SetPageTransitionAnimationAction = {
  type: typeof SET_PAGE_TRANSITION_ANIMATION
  animationName: string
}

export type SetSocAction = {
  type: typeof SET_SOC
  soc: number
}

export type SetIncomingCallPopupVisibiity = {
  type: typeof SET_INCOMING_CALL_POPUP_VISIBILITY
  show: boolean
}

export type SetVoiceSearch = {
  type: typeof SET_VOICE_SEARCH
  voiceSearch: boolean
}

export type SetPopupVisible = {
  type: typeof SET_POPUP_VISIBLE
  message: string
}

export type SetPopupHidden = {
  type: typeof SET_POPUP_HIDDEN
}
