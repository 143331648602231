import { song } from "./types"

//covers
import webtunes from "@pag/center/assets/mp3/covers/webtunes_vol3.png"
import webtunes2 from "@pag/center/assets/mp3/covers/webtunes_vol4.png"
import podcast from "@pag/center/assets/mp3/podcasts/covers/podcast_cover.jpg"

import octava_cover from "@pag/center/assets/mp3/radio/covers/octava.png"
import pstudio_cover from "@pag/center/assets/mp3/radio/covers/pstudio.png"
import studio_cover from "@pag/center/assets/mp3/radio/covers/studio.png"
import urban_cover from "@pag/center/assets/mp3/radio/covers/urban.png"
import slm_cover from "@pag/center/assets/mp3/radio/covers/slmradio3.png"

import qpkt_cover from "@pag/center/assets/mp3/radio/covers/qpkt.png"
import hnk_cover from "@pag/center/assets/mp3/radio/covers/hnk.png"
import popsummer_cover from "@pag/center/assets/mp3/radio/covers/popsummer.png"
import synthwave_cover from "@pag/center/assets/mp3/radio/covers/synthwave.png"
import talk_cover from "@pag/center/assets/mp3/radio/covers/talk.png"

export const songListUSB: song[] = [
  {
    artist: "Various Artists",
    title: "Radically You",
    album: "Porsche Webtunes Vol.3 - Cayman",
    cover: webtunes,
    filename: "sign_here.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Pushing Boundaries",
    album: "Porsche Webtunes Vol.3 - Cayman",
    cover: webtunes,
    filename: "afro_blue.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Flat-6",
    album: "Porsche Webtunes Vol.3 - Cayman",
    cover: webtunes,
    filename: "along_the_ocean.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Peridot Shimmer",
    album: "Porsche Webtunes Vol.3 - Cayman",
    cover: webtunes,
    filename: "drive.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Bpm sets Rpm",
    album: "Porsche Webtunes Vol.3 - Cayman",
    cover: webtunes,
    filename: "life_intensified.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Depth-sounder",
    album: "Porsche Webtunes Vol.3 - Cayman",
    cover: webtunes,
    filename: "one.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Complete",
    album: "Porsche Webtunes Vol.3 - Cayman",
    cover: webtunes,
    filename: "ambient.mp3",
    genre: "Rock"
  },
  {
    artist: "Various Artists",
    title: "Moonlight Ride",
    album: "Porsche Webtunes Vol.4 - Boxter",
    cover: webtunes2,
    filename: "moonlight_drive.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Across The Space",
    album: "Porsche Webtunes Vol.4 - Boxter",
    cover: webtunes2,
    filename: "across_the_space.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Along The Ocean",
    album: "Porsche Webtunes Vol.4 - Boxter",
    cover: webtunes2,
    filename: "along_the_ocean.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Unleashed",
    album: "Porsche Webtunes Vol.4 - Boxter",
    cover: webtunes2,
    filename: "unleashed.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Evening Cruise",
    album: "Porsche Webtunes Vol.4 - Boxter",
    cover: webtunes2,
    filename: "to_the_sea.mp3",
    genre: "Pop"
  },
  {
    artist: "Various Artists",
    title: "Independence Day",
    album: "Porsche Webtunes Vol.4 - Boxter",
    cover: webtunes2,
    filename: "independence_day.mp3",
    genre: "Pop"
  }
]

export const songListRadio: song[] = [
  { title: "Okkitava", artist: "", album: "", cover: octava_cover, filename: "octava.mp3", genre: "Classic" },
  { title: "B Studio", artist: "", album: "", cover: pstudio_cover, filename: "pstudio.mp3", genre: "Electronic " },
  { title: "Studio", artist: "", album: "", cover: studio_cover, filename: "studio.mp3", genre: "Rock" },
  { title: "Urban Studio", artist: "", album: "", cover: urban_cover, filename: "urban.mp3", genre: "Hip Hop / Latin" },
  { title: "SLM Radio 3", artist: "", album: "", cover: slm_cover, filename: "slm_3.mp3", genre: "Contemporary" }
]

export const songListOnlineRadio: song[] = [
  { title: "Radio HNK", artist: "", album: "", cover: hnk_cover, filename: "hnk.mp3", genre: "Contemporary" },
  { title: "BPKT", artist: "", album: "", cover: qpkt_cover, filename: "qpkt.mp3", genre: "International Hits" },
  { title: "Synthwave", artist: "", album: "", cover: synthwave_cover, filename: "synthwave.mp3", genre: "Retro Music" },
  { title: "Pop Summer", artist: "", album: "", cover: popsummer_cover, filename: "popsummer.mp3", genre: "Pop" },
  { title: "Talk", artist: "", album: "", cover: talk_cover, filename: "talk.mp3", genre: "Podcasts" }
]

export const podcasts: song[] = [
  {
    title: "About leaders who care",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    cover: podcast,
    filename: "podcast.mp3",
    genre: "Society & Culture"
  },
  {
    title: "About intersectionality",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    cover: podcast,
    filename: "podcast_about_intersectionality_1.mp3",
    genre: "Technology"
  },
  {
    title: "About next rolemodels",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    cover: podcast,
    filename: "podcast_about_next_role_models_1.mp3",
    genre: "Science"
  },
  {
    title: "About new currencies",
    artist: "Next Visions - Today's Masterminds about Topics of Tomorro‪w‬",
    album: "",
    cover: podcast,
    filename: "podcast__about_new_currencies_1.mp3 ",
    genre: "Science"
  }
]

export const favourtiesRadios: song[] = [
  songListOnlineRadio[0]
]
