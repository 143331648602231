// This is a definition of setting data. It is a structure for everything that
// could go into settings.

import { IconType } from "@pag/center/components/icon/iconType"
import { ESourceNames } from "@pag/center/components/quickFilter/quickFilterIconWithSpeaker/types"

// Because settings data isn’t editable (you cannot add new languages from the
// UI) all the objects that it produces a frozen. That allows us to compare
// them directly.

function obj(parent = null) {
  return Object.create(parent)
}

function noMoreThanOneDigitAfterZero(num: number): string {
  return Number.isInteger(num) ? String(num) : num.toFixed(1)
}

function toClosestMultiple(num: number, multiple: number): number {
  return Math.round(num / multiple) * multiple
}

function alignWithStep(num: number, step: number = 1): string {
  const value = toClosestMultiple(num, step)
  return (
    Number.isInteger(step)
    ? String(value)
    : (value).toFixed(1)
  )
}

function lowerPrecision(num: number): number {
  return Math.ceil(num / 10) * 10
}

type Entry = {
  label: string
  value?: any
  formatter?: (num: number) => string
  toBase?: (num: number) => number
  fromBase?: (num: number) => number
  min?: number
}

// @NOTE(kirill): Creates an immutable array of immutable objects, needed when
// there’s use for read-only definitions. If you try to write to it you’ll get
// an error. That would let you know that you have a bug. Otherwise you might
// end up with code that changes things that aren’t supposed to change.

function populate(array: any[], names: string[] = [ "label", "value" ]): Readonly<Entry[]> {
  return Object.freeze(array.map((element) => Object.freeze(
    Array.isArray(element)
    ? element.reduce(
      (result, item, index) => {
        result[names[index]] = item
        return result
      },
      obj()
    )
    : { label: element }
  )))
}

function celsiusToFahrenheit(num: number): number {
  return toClosestMultiple((num * 1.8) + 32, 1)
}

function fahrenheitToCelsius(num: number): number {
  return toClosestMultiple((num - 32) / 1.8, 0.5)
}

// DISTANCE
const metersInKm = 1000
const metersInMile = 1609.344
const metersInFt = 0.3048
const ftInMile = 5280
const metersToKm = (num: number): number => num / metersInKm
const kmToMeters = (num: number): number => num * metersInKm
const metersToMiles = (num: number): number => num / metersInMile
const milesToMeters = (num: number): number => num * metersInMile
const metersToFt = (num: number): number => num / metersInFt
const ftToMeters = (num: number): number => num * metersInFt

// SPEED
const msInKmh = 5 / 18
const msInMph = 0.44704
const msToKmh = (num: number): number => num / msInKmh
const kmhToMs = (num: number): number => num * msInKmh
const msToMph = (num: number): number => num / msInMph
const mphToMs = (num: number): number => num * msInMph

// CONSUMPTION (EV)
const kmkWh_to_kWh100km = (num: number): number => 100 / num
const kmkWh_to_kWh100mi = (num: number): number => 160.9344 / num
const kmkWh_to_mikWh = (num: number): number => num / 1.609344

const kWh100km_to_kmkWh = (num: number): number => 100 / num
const kWh100mi_to_kmkWh = (num: number): number => 160.9344 / num
const mikWh_to_kmkWh = (num: number): number => 1.609344 * num

// PRESSURE
const paInBar = 100000 // 100 000 Pa
const paInPsi = 6894.757 // 6.894757 * 10³ Pa
const paInKpa = 1000
const paToBar = (num: number) => num / paInBar
const barToPa = (num: number) => num * paInBar
const paToPsi = (num: number) => num / paInPsi
const psiToPa = (num: number) => num * paInPsi
const paToKpa = (num: number) => num / paInKpa
const kpaToPa = (num: number) => num * paInKpa

const lang_table = [
  [ "Deutsch",       "de_DE" ],
  [ "English (GB)",  "en_GB" ],
  [ "English (USA)", "en_US" ],
  [ "Español",       "es_ES" ],
  [ "French",        "fr_FR" ],
  [ "Korean",        "ko_KR" ],
  [ "Italian",       "it_IT" ],
  [ "Dutch",         "nl_NL" ],
  [ "Japanese",      "ja_JP" ],
  [ "Portuguese",    "pt_BR" ],
  [ "Russian",       "ru_RU" ],
  [ "Chinese",       "zh_CN" ],
  [ "Taiwanese",     "zh_TW" ]
]

// Exported object has a form of {de_DE: {label: "Deutsch", value: "de_DE"}, ...}
const langs: { [index: string]: Entry } = Object.freeze(populate(lang_table).reduce(
  function (result, lang) {
    result[lang.value] = lang
    return result
  },
  obj()
))

const today = (function () {
  const now = new Date()
  now.setHours(9)
  now.setMinutes(11)
  return new Date(now)
}())

const timezones = populate([
  "GMT +01:00 Berlin"
])

const timeFormats = populate([
  "Settings_DateAndTime_SetTimeFormat_24h",
  "Settings_DateAndTime_SetTimeFormat_12h"
])

const formatTime = (date: Date, format = timeFormats[0]) => date.toLocaleTimeString(
  "en",
  {
    // @ts-ignore
    timeStyle: "short",
    hour12: format === timeFormats[1]
  }
// NOTE(kirill): Intl doesn’t let you not show leading zero in 24-hour format.
).split(" ").map((num, index) => (
  index === 0
  ? (
    num.length === 5 && num.slice(0, 1) === "0"
    ? num.slice(1)
    : num
  )
  : num
)).join(" ")

const simpleDate = (date: Date): {[index: string]: string} => ({
  day: String(date.getDate()).padStart(2, "0"),
  month: String(date.getMonth() + 1).padStart(2, "0"),
  year: String(date.getFullYear())
})

const dateFormats = populate(
  [
    [
      "Settings_System_DateAndTime_SetDateManually_SlideOut1",
      function (date: Date): string {
        const {day, month, year} = simpleDate(date)
        return `${day}.${month}.${year}`
      }
    ],
    [
      "Settings_System_DateAndTime_SetDateManually_SlideOut2",
      function (date: Date): string {
        const {day, month, year} = simpleDate(date)
        return `${month}/${day}/${year}`
      }
    ],
    [
      "Settings_System_DateAndTime_SetDateManually_SlideOut3",
      function (date: Date): string {
        const {day, month, year} = simpleDate(date)
        return `${year}-${month}-${day}`
      }
    ]
  ],
  [ "label", "formatter" ]
)

const speedUnits = populate(
  [
    [
      "Settings_System_Units_SpeedAndDistance_Speed_kmh",
      (num: number): string => String(Math.floor(msToKmh(num))),
      kmhToMs,
      msToKmh
    ],
    [
      "Settings_System_Units_SpeedAndDistance_Speed_mph",
      (num: number): string => String(Math.floor(msToMph(num))),
      mphToMs,
      msToMph
    ]
  ],
  [ "label", "formatter", "toBase", "fromBase" ]
)

const distanceUnits = populate(
  [
    [
      "Settings_System_Units_SpeedAndDistance_Distance_km",
      (num: number): string => String(Math.floor(metersToKm(num))),
      kmToMeters,
      metersToKm,
      metersInKm
    ],
    [
      "Settings_System_Units_SpeedAndDistance_Distance_mi",
      (num: number): string => String(Math.floor(metersToMiles(num))),
      milesToMeters,
      metersToMiles,
      metersInMile
    ],
    [
      "Settings_System_Units_SpeedAndDistance_Distance_m",
      (num: number): string => String(lowerPrecision(num)),
      (num: number): number => num,
      (num: number): number => num
    ],
    [
      "Settings_System_Units_SpeedAndDistance_Distance_ft",
      (num: number): string => String(lowerPrecision(metersToFt(num))),
      ftToMeters,
      metersToFt
    ],
  ],
  [ "label", "formatter", "toBase", "fromBase", "min" ]
)

const speedAndDistanceUnits = populate([
  "Settings_System_Units_SpeedAndDistance_kmh",
  "Settings_System_Units_SpeedAndDistance_mph",
])

const chargeSpeedUnits = populate(
  [
    [
      "Settings_System_Units_Charge_km_min",
      (num: number): string => metersToKm(num).toFixed(1),
      kmToMeters,
      metersToKm
    ],
    [
      "Settings_System_Units_Charge_mi_min",
      (num: number): string => metersToMiles(num).toFixed(1),
      milesToMeters,
      metersToMiles
    ]
  ],
  [ "label", "formatter", "toBase", "fromBase" ]
)

const temperatureUnits = populate(
  [
    [
      "Settings_System_Units_Temperature_Celius",
      (num: number): string => alignWithStep(num, 0.5),
      0.5
    ],
    [
      "Settings_System_Units_Temperature_Fahrenheit",
      (num: number): string => alignWithStep(celsiusToFahrenheit(num)),
      1
    ]
  ],
  [ "label", "formatter", "step" ]
)

const pressureUnits = populate(
  [
    [
      "Settings_System_Units_Pressure_bar",
      (num: number): string => noMoreThanOneDigitAfterZero(paToBar(num)),
      barToPa,
      paToBar
    ],
    [
      "Settings_System_Units_Pressure_psi",
      (num: number): string => noMoreThanOneDigitAfterZero(paToPsi(num)),
      psiToPa,
      paToPsi
    ],
    [
      "Settings_System_Units_Pressure_kpa",
      (num: number): string => noMoreThanOneDigitAfterZero(paToKpa(num)),
      kpaToPa,
      paToKpa
    ]
  ],
  [ "label", "formatter", "toBase", "fromBase" ]
)

const consumptionUnits = populate(
  [
    [
      "Settings_System_Units_Consumption_kwh_100km",
      (num: number): string => kmkWh_to_kWh100km(num).toFixed(1),
      kWh100km_to_kmkWh,
      kmkWh_to_kWh100km
    ],
    [
      "Settings_System_Units_Consumption_km_kwh",
      (num: number): string => num.toFixed(1),
      (num: number): number => num,
      (num: number): number => num
    ],
    [
      "Settings_System_Units_Consumption_kwh_100mi",
      (num: number): string => kmkWh_to_kWh100mi(num).toFixed(1),
      kWh100mi_to_kmkWh,
      kmkWh_to_kWh100mi
    ],
    [
      "Settings_System_Units_Consumption_mi_kwh",
      (num: number): string => kmkWh_to_mikWh(num).toFixed(1),
      mikWh_to_kmkWh,
      kmkWh_to_mikWh
    ]
  ],
  [ "label", "formatter", "toBase", "fromBase" ]
)

const touchTones = populate([
  "Settings_TouchTone_Off",
  "Settings_TouchTone_Quiet",
  "Settings_TouchTone_Medium",
  "Settings_TouchTone_Loud"
])

const displayScope = populate([
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Assistance",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_G-Force",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_All-wheel",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_PDCC",
  // "Settings_Display_InstrumentCluster_ConfigureDisplayScope_TyreInfo",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Map",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_ExtendedMap",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_NightViewAssist",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Reduced",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Navigation",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Trip",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_SportChrono",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_DriveMode",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Media",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Online",
])

const displayCustomizedView = populate([
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Altitude",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Arrival",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Compass",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Date",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Decelaration",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_DrivingTimeAndDistance",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_LateralAcceleration",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_LongitudinalAcceleration",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_PhoneInfo",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_StationOrTrack",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Voltage",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_BatteryTemp",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_EmptyLine",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_SOC"
])

const hapticFeedback = populate([
  "Settings_HapticFeedback_Off",
  "Settings_HapticFeedback_Low",
  "Settings_HapticFeedback_Med",
  "Settings_HapticFeedback_High"
])

const focusMovementSpeed = populate([
  "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed_Slow",
  "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed_Moderate",
  "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed_Fast"
])

const hudView = populate([
  "Settings_Display_HUD_View_Slideout1",
  "Settings_Display_HUD_View_Slideout2",
  "Settings_Display_HUD_View_Slideout3",
  "Settings_Display_HUD_View_Slideout4",
  "Settings_Display_HUD_View_Slideout5",
  "Settings_Display_HUD_View_Slideout6",
  "Settings_Display_HUD_View_Slideout7"
])

const hudDayNightView = populate([
  "Settings_Display_HUD_DayAndNightView_Slideout1",
  "Settings_Display_HUD_DayAndNightView_Slideout2",
  "Settings_Display_HUD_DayAndNightView_Slideout3"
])

const hudIncomingCalls = populate([
  "Settings_Display_HUD_IncomingCalls_Slideout1",
  "Settings_Display_HUD_IncomingCalls_Slideout2"
])

const hudCustomAdjustMainArea = populate([
  "Settings_Display_HUD_Custom_Adjust_MainArea_1",
  "Settings_Display_HUD_Custom_Adjust_MainArea_2"
])

const phoneBookSortingCriteria = populate([
  "Settings_Phone_PhoneBookSettings_SortingCriteria_Slideout1",
  "Settings_Phone_PhoneBookSettings_SortingCriteria_Slideout2",
  "Settings_Phone_PhoneBookSettings_SortingCriteria_Slideout3"
])

const navigationAvoid = populate(
  [
    [ "Settings_Navigation_RouteOptions_Avoid_Freeway",              "icon-freeway" ],
    [ "Settings_Navigation_RouteOptions_Avoid_TollRoads",            "icon-freeway_toll" ],
    [ "Settings_Navigation_RouteOptions_Avoid_Ferries",              "icon-avoid_ferry_car_train_not" ],
    [ "Settings_Navigation_RouteOptions_Avoid_Tunnels",              IconType.icon_tunnel ],
    [ "Settings_Navigation_RouteOptions_Avoid_SeasonalRestrictions", "icon-altitude" ]
  ],
  [ "label", "icon" ]
)

const navigationDayAndNightView = populate([
  "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView_Day",
  "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView_Night",
  "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView_Auto"
])

const privacyModes = populate([
  "Settings_PrivacyAndPorscheConnect_PrivateMode_Slideout1",
  "Settings_PrivacyAndPorscheConnect_PrivateMode_Slideout2"
])

const vehicleSpoilerPosition = populate([
  "Settings_Vehicle_ManualCleaningPositionSpoiler_Retracted",
  "Settings_Vehicle_ManualCleaningPositionSpoiler_Extended"
])

const vehicleParkingBrake = populate([
  "Settings_Vehicle_ParkingBrake_Closed",
  "Settings_Vehicle_ParkingBrake_Open"
])

const vehicleDoorUnlocking = populate([
  "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout1",
  "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout2",
  "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout3",
  "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout4"
])

const vehicleAutoUnlock = populate([
  "Settings_Vehicle_VehicleLockingSystems_AutoUnlock_Slideout1",
  "Settings_Vehicle_VehicleLockingSystems_AutoUnlock_Slideout2",
  "Settings_Vehicle_VehicleLockingSystems_AutoUnlock_Slideout3"
])

const vehicleTyrePressureSelection = populate([
  "Settings_Vehicle_TyrePressureMonitoring_Selection_Slideout1",
  "Settings_Vehicle_TyrePressureMonitoring_Selection_Slideout2",
  "Settings_Vehicle_TyrePressureMonitoring_Selection_Slideout3"
])

const vehicleShortcutButtons = populate([
  "Settings_Vehicle_ShortcutButtons_Slideout1",
  "Settings_Vehicle_ShortcutButtons_Slideout2",
  "Settings_Vehicle_ShortcutButtons_Slideout3",
  "Settings_Vehicle_ShortcutButtons_Slideout4"
])

const assistanceWarningTime = populate([
  "Settings_AssistanceSystems_NightViewAssist_WarningTime_Slideout1",
  "Settings_AssistanceSystems_NightViewAssist_WarningTime_Slideout2",
  "Settings_AssistanceSystems_NightViewAssist_WarningTime_Slideout3"
])

const assistanceSpeedLimitsAdoption = populate([
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut1",
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut2",
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut3",
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut4"
])

const assistanceParkAssistVolume = populate([
  "Settings_AssistanceSystems_ParkAssist_Volume_Slideout1",
  "Settings_AssistanceSystems_ParkAssist_Volume_Slideout2",
  "Settings_AssistanceSystems_ParkAssist_Volume_Slideout3"
])

const assistanceCollisionWarning = populate([
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout1",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout2",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout3",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout4"
])

const assistanceDistanceWarning = populate([
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout1",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout2",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout3",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout4"
])

const assistanceLaneDepartureWarning = populate([
  "Settings_AssistanceSystems_LaneDepartureWarning_Volume_Slideout1",
  "Settings_AssistanceSystems_LaneDepartureWarning_Volume_Slideout2",
  "Settings_AssistanceSystems_LaneDepartureWarning_Volume_Slideout3"
])

const assistanceLaneChangeBrightness = populate([
  "Settings_AssistanceSystems_LaneChangeAssist_Brightness_Slideout1",
  "Settings_AssistanceSystems_LaneChangeAssist_Brightness_Slideout2",
  "Settings_AssistanceSystems_LaneChangeAssist_Brightness_Slideout3"
])

const tripPersonalConfig = populate([
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_EmptyLines",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_DriveTimeSince",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_ContinuousDriveTime",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_DistanceSince",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_ContinuousDistance",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_ConsumptionSince",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_ContinuousConsumption",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_SpeedSince",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_ContinuousSpeed",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_ArrivalAndDistanceToDestination",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_ArrivalAndDistanceToStopover",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_TimeAndDistanceToDestination",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_TimeAndDistanceToStopover",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_Range",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_Speed",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_MaximumSpeed",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_Time",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_Date",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_OutsideTemperature",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_Height",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_DirectionOfTravel",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_CurrentLongitudinalAcceleration",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_CurrentLateralAcceleration",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_MaxLongitudinalAcceleration",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_MaxLateralAcceleration",
  "Settings_Car_Trip_ConfigureTripPersonally_line1_SlideOut_SteeringAngle"
])

const launcherContextOption = populate([
  "Settings_Launcher_Context_Options_Detail_Off",
  "Settings_Launcher_Context_Options_Detail_Suggestions"
])

const notificationCenterAllow = populate([
  "Settings_NotificationCenter_Allow_Slideout1",
  "Settings_NotificationCenter_Allow_Slideout2",
  "Settings_NotificationCenter_Allow_Slideout3",
  "Settings_NotificationCenter_Allow_Slideout4",
  "Settings_NotificationCenter_Allow_Slideout5",
  "Settings_NotificationCenter_Allow_Slideout6",
  "Settings_NotificationCenter_Allow_Slideout7"
])

const calendarWeekStart = populate([
  "Calendar_Settings_StartWeek_Slideout1",
  "Calendar_Settings_StartWeek_Slideout2",
  "Calendar_Settings_StartWeek_Slideout3",
  "Calendar_Settings_StartWeek_Slideout4",
  "Calendar_Settings_StartWeek_Slideout5",
  "Calendar_Settings_StartWeek_Slideout6",
  "Calendar_Settings_StartWeek_Slideout7",
  "Calendar_Settings_StartWeek_Slideout8"
])

const mediaSources = populate(
  [
    [ "MediaAppRootRouter_SourceQuickFilterList_Favorites",   "icon-star",                           ESourceNames.FAVORITES ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Radio",       "icon-radio",                          ESourceNames.RADIO ],
    [ "MediaAppRootRouter_SourceQuickFilterList_OnlineRadio", "icon-online_radio",                   ESourceNames.ONLINE_RADIO ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Sdars",       "icon-sirius_xm",                      ESourceNames.SIRIUS_XM ],
    [ "MediaAppRootRouter_SourceQuickFilterList_OnlineMedia", "icon-logo_apple_music",               ESourceNames.APPLE_MUSIC ],
    [ "Apple Podcasts",                                       "icon-logo_apple_podcasts icon-small", ESourceNames.APPLE_PODCASTS ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Carplay",     "icon-apple_carplay",                  ESourceNames.APPLE_CARPLAY ],
    [ "MediaAppRootRouter_SourceQuickFilterList_AndroidAuto", "icon-android_auto",                   ESourceNames.ANDROID_AUTO ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Usb",         "icon-usb",                            ESourceNames.USB ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Bluetooth",   "icon-source_bluetooth",               ESourceNames.BLUETOOTH ]
  ],
  [ "label", "icon", "name" ]
)

const auxiliaryCoolingZones = populate([
  "Auxiliary_Cooling_Zones_i18n_Label#Driver",
  "Auxiliary_Cooling_Zones_i18n_Label#CoDriver",
  // "Auxiliary_Cooling_Zones_i18n_Label#RearLeft",
  // "Auxiliary_Cooling_Zones_i18n_Label#RearRight"
])

const auxiliaryCoolingPrecondition = populate([
  "Auxiliary_Cooling_BevAuxCooling_Activation_i18n_Label#Off",
  "Auxiliary_Cooling_BevAuxCooling_Activation_i18n_Label#On"
])

const brightness = Object.freeze({
  min: -5,
  max: 5,
  suffix: ""
})

const volume = Object.freeze(Object.assign({}, brightness))

const speedDeviation = Object.freeze({
  min: 0,
  max: kmhToMs(10),
  suffix: "Settings_System_Units_Speed"
})

const speedLimit = Object.freeze({
  min: 0,
  max: kmhToMs(200),
  step: 10,
  suffix: "Settings_System_Units_Speed"
})

const adaptiveSpeedLimit = Object.freeze({
  min: kmhToMs(30),
  max: kmhToMs(210),
  step: 10,
  suffix: "Settings_System_Units_Speed"
})

const charge = Object.freeze({
  middle: 0,
  min: 3,
  max: 90,
  suffix: "%",
  unit: "%"
})

const navigationAnnouncements = Object.freeze({
  min: 0,
  max: 11,
  suffix: ""
})

const lightAfterGlow = Object.freeze({
  min: 10,
  max: 240,
  step: 10,
  suffix: "s"
})

const lightBrightness = Object.freeze({
  min: 0,
  max: 100,
  step: 5,
  suffix: "%"
})

const contrast = Object.freeze({
  min: -9,
  max: 9,
  step: 1,
  suffix: ""
})

const volumeReduction = Object.freeze({
  min: 0,
  max: 5,
  step: 0.5,
  suffix: ""
})

const chronoMaxDelta = Object.freeze({
  min: 0.5,
  max: 3,
  step: 0.5,
  suffix: "%"
})

const chronoDeviation = Object.freeze({
  min: 5,
  max: 30,
  step: 5,
  suffix: "%"
})

export default Object.freeze({
  langs,
  today,
  timezones,
  timeFormats,
  formatTime,
  dateFormats,
  speedAndDistanceUnits,
  distanceUnits,
  chargeSpeedUnits,
  speedUnits,
  temperatureUnits,
  pressureUnits,
  consumptionUnits,
  touchTones,
  brightness,
  displayScope,
  displayCustomizedView,
  hapticFeedback,
  focusMovementSpeed,
  speedDeviation,
  speedLimit,
  hudView,
  hudDayNightView,
  hudIncomingCalls,
  hudCustomAdjustMainArea,
  phoneBookSortingCriteria,
  volume,
  charge,
  navigationAvoid,
  navigationAnnouncements,
  navigationDayAndNightView,
  auxiliaryCoolingZones,
  auxiliaryCoolingPrecondition,
  privacyModes,
  vehicleSpoilerPosition,
  vehicleParkingBrake,
  vehicleShortcutButtons,
  vehicleDoorUnlocking,
  vehicleAutoUnlock,
  vehicleTyrePressureSelection,
  lightAfterGlow,
  lightBrightness,
  contrast,
  assistanceWarningTime,
  adaptiveSpeedLimit,
  assistanceSpeedLimitsAdoption,
  volumeReduction,
  assistanceParkAssistVolume,
  assistanceCollisionWarning,
  assistanceDistanceWarning,
  assistanceLaneDepartureWarning,
  assistanceLaneChangeBrightness,
  chronoMaxDelta,
  chronoDeviation,
  tripPersonalConfig,
  launcherContextOption,
  notificationCenterAllow,
  calendarWeekStart,
  mediaSources,
  fahrenheitToCelsius,
  celsiusToFahrenheit,
  kmhToMs,
  mphToMs
})
