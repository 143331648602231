import React from "react"
import cx from "classnames"
import { useTranslation } from "react-i18next"

import ownTranslationsDe from "../../translations/no_translation_de.json"
import ownTranslationsEn from "../../translations/no_translation_en.json"

import "./i18nLabel.scss"

interface Ii18nLabel {
  text: string | TemplateStringsArray
  className?: string
  id?: string
  nonI18n?: boolean
  interpolationKeys?: any
}

const I18nLabel: React.FunctionComponent<Ii18nLabel> = (props) => {
  const { t, i18n } = useTranslation("center")

  const lng = localStorage.getItem("locale")

  const getLabel = () => {
    let text: string | TemplateStringsArray = ""

    //@ts-ignore
    if (lng === "de_DE" && ownTranslationsDe[props.text]) {
      // @ts-ignore
      text = ownTranslationsDe[props.text]
      // @ts-ignore
    } else if (ownTranslationsEn[props.text]) {
      // @ts-ignore
      text = ownTranslationsEn[props.text]
    } else {
      text = props.text
    }

    let exists = false
    if (text !== undefined) {
      // @ts-ignore
      exists = i18n.exists(text)
    }

    return (
      <div id={props.id} className={cx("i18nLabel", props.className)}>
        <span className="label__span">{exists ? t(text, { ...props.interpolationKeys }) : text}</span>
      </div>
    )
  }

  return getLabel()
}

export default I18nLabel
