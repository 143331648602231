import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_ACTIVE_SOURCE = "SET_ACTIVE_SOURCE"
export const SET_PLAYING_SOURCE = "SET_PLAYING_SOURCE"

export enum ESourceNames {
  RADIO = "radio",
  SEARCH = "search",
  FAVORITES = "favorites",
  ONLINE_RADIO = "online-radio",
  APPLE_MUSIC = "apple-music",
  APPLE_MUSIC_RADIO = "apple-music-radio",
  APPLE_PODCASTS = "apple-podcasts",
  APPLE_CARPLAY = "apple-carplay",
  ANDROID_AUTO = "android-auto",
  USB = "usb",
  BLUETOOTH = "bluetooth",
  SIRIUS_XM = "sirius_xm",
  MORE = "more"
}

export type ReduxInitAction = { type: "@@INIT" }

export type setActiveSourceAction = {
  type: typeof SET_ACTIVE_SOURCE
  activeSource: string
}

export type setPlayingSourceAction = {
  type: typeof SET_PLAYING_SOURCE
  playingSource: string
}

export type SourceState = {
  activeSource: string
  playingSource: string
}

export type Action = ReduxInitAction | setActiveSourceAction

export type Store = ReduxStore<SourceState, Action>

export type Dispatch = ReduxDispatch<Action>

export type SourceSettingType = setActiveSourceAction | setPlayingSourceAction
