import {
  SetMainTabBarItemsAction,
  SET_MAIN_TAB_BAR_ITEMS,
  MainTabBarState,
  SetCloseWizard,
  CloseWizardState,
  CLOSE_WIZARD,
  REQUEST_CLOSE_WIZARD_SWITCH,
  RequestCloseWizardSwitch
} from "./types"
import { carTile, mediaTile, navigationTile, phoneTile } from "@pag/center/components/launcher/launcherTiles"

const initialState: MainTabBarState = [
  navigationTile,
  mediaTile,
  phoneTile,
  carTile
]

export function mainTabBarItemsReducer(
  state = initialState,
  action: SetMainTabBarItemsAction
): MainTabBarState {
  switch (action.type) {
    case SET_MAIN_TAB_BAR_ITEMS:
      return action.items
    default:
      return state
  }
}

const initialCloseWizardState = {
  closeWizard: false,
  switchRequested: false
}

export function closeWizardReducer(
  state = initialCloseWizardState,
  action: SetCloseWizard | RequestCloseWizardSwitch
): CloseWizardState {
  switch (action.type) {

  case REQUEST_CLOSE_WIZARD_SWITCH:
    return (
      state.closeWizard
      ? {
        closeWizard: true,
        switchRequested: true
      }
      : {
        closeWizard: false,
        switchRequested: false
      }
    )

  case CLOSE_WIZARD:
    return (
      action.close
      ? {
        closeWizard: true,
        switchRequested: false
      }
      : {
        closeWizard: false,
        switchRequested: false
      }
    )

  default:
    return state
  }
}
