import {
  UPDATE_LOCATION_HISTORY,
  SET_HOME_BUTTON_URL,
  SET_PAGE_TRANSITION_ANIMATION,
  SET_SOC,
  SET_INCOMING_CALL_POPUP_VISIBILITY,
  SET_VOICE_SEARCH,
  SET_POPUP_VISIBLE
} from "./types"
import { Location } from "history"

export function updateLocationHistory(location: Location, source: string) {
  return { type: UPDATE_LOCATION_HISTORY, location, source }
}

export function setHomeButtonUrl(url: string) {
  return { type: SET_HOME_BUTTON_URL, url }
}

export function setPageTransitionAnimation(animationName: string) {
  return { type: SET_PAGE_TRANSITION_ANIMATION, animationName }
}

export function setSoc(soc: number) {
  return { type: SET_SOC, soc }
}

export function setIncomingCallPopupVisibility(show: boolean) {
  return { type: SET_INCOMING_CALL_POPUP_VISIBILITY, show }
}

export function setVoiceSearch(voiceSearch: boolean) {
  return { type: SET_VOICE_SEARCH, voiceSearch }
}

export function setPopupVisible(message: string) {
  return { type: SET_POPUP_VISIBLE, visible: true, message }
}
