import {
  UPDATE_PHONE_SEARCH,
  SET_BREAKDOWN_CALL,
  UpdatePhoneSearchAction,
  SetBreakdownCallAction,
  Contact,
  SetOngoingCall,
  SET_ONGOING_CALL,
  PhoneNumber,
  KnownNumber,
  Call
} from "./types"

const phoneSearchInitialState: boolean = false

export function phoneSearchReducer(state = phoneSearchInitialState, action: UpdatePhoneSearchAction) {
  switch (action.type) {
    case UPDATE_PHONE_SEARCH:
      return action.active
    default:
      return state
  }
}

const ongoingCallIntialState: PhoneNumber | null = null

export function ongoingCallReducer(state: PhoneNumber | null  = ongoingCallIntialState, action: SetOngoingCall) {
  switch (action.type) {
    case SET_ONGOING_CALL:
      return action.ongoing || null
    default:
      return state
  }
}

const contacts: readonly Readonly<Contact>[] = Object.freeze([
  {
    firstName: "Maria",
    lastName: "Bianchi",
    mobile: "+49151223844",
    private: "+49151223844"
  },
  {
    firstName: "John",
    lastName: "Doe",
    mobile: "015142395344",
  },
  {
    firstName: "Andreas",
    lastName: "Falk",
    mobile: "016453942911711",
  },
  {
    firstName: "Li",
    lastName: "Ming",
    mobile: "+03284920523",
  }
].map(item => Object.freeze(item)));

type PhoneScreenState = {
  breakdownCallActive: boolean
  contacts: Readonly<Contact>[]
  favorites: Readonly<KnownNumber>[]
  calls: Readonly<Call>[]
}

const phoneScreenInitialState: PhoneScreenState = {
  breakdownCallActive: false,
  contacts: contacts.slice(),
  favorites: [
    {contact: contacts[0], kind: "mobile"},
    {contact: contacts[1], kind: "mobile"},
    {contact: contacts[2], kind: "mobile"},
    {contact: contacts[3], kind: "mobile"},
  ],
  calls: [
    {
      kind: "outgoing",
      number: { number: "+4915122395844" },
      date: new Date("2020-01-17T13:00:00")
    } as const,
    {
      kind: "outgoing",
      number: { number: "+491703279617" },
      date: new Date("2020-01-16T14:00:00")
    } as const,
    {
      kind: "outgoing",
      number: { number: "+491703279617" },
      date: new Date("2020-01-16T14:01:00")
    } as const,
    {
      kind: "outgoing",
      number: { number: "+491703279617" },
      date: new Date("2020-01-16T14:02:00")
    } as const,
    {
      kind: "missed",
      number: { contact: contacts[0], kind: "private" },
      date: new Date("2020-01-16T13:02:00")
    } as const,
    {
      kind: "incoming",
      number: { number: "017087817052" },
      date: new Date("2020-01-12T10:02:00")
    } as const,
    {
      kind: "missed",
      number: { number: "+393920250190" },
      date: new Date("2020-01-11T14:02:00")
    } as const,
  ]
}

export function phoneScreenReducer(state = phoneScreenInitialState, action: SetBreakdownCallAction) {
  switch (action.type) {
    case SET_BREAKDOWN_CALL:
      return {
        ...state,
        breakdownCallActive: action.active
      }
    default:
      return state
  }
}
