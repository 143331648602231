export const MAKE_ROOM = "MAKE_ROOM"
export const CREATE_TIMER = "CREATE_TIMER"
export const EDIT_TIMER = "EDIT_TIMER"
export const TOGGLE_TIMER = "TOGGLE_TIMER"
export const REMOVE_TIMER = "REMOVE_TIMER"
export const CREATE_PROFILE = "CREATE_PROFILE"
export const EDIT_PROFILE = "EDIT_PROFILE"
export const TOGGLE_PROFILE = "TOGGLE_PROFILE"
export const REMOVE_PROFILE = "REMOVE_PROFILE"
export const EDIT_AUX_COOLING = "EDIT_AUX_COOLING"

export type MakeRoomAction = {
  type: typeof MAKE_ROOM,
  value: boolean
}

export type CreateTimerAction = {
  type: typeof CREATE_TIMER
}

export type ToggleTimerAction = {
  type: typeof TOGGLE_TIMER,
  timer: Timer
}

export type RemoveTimerAction = {
  type: typeof REMOVE_TIMER,
  timer: Timer
}

export type EditTimerAction = {
  type: typeof EDIT_TIMER
  timer: Timer
  data: { [index: string]: any }
}

export type CreateProfileAction = {
  type: typeof CREATE_PROFILE
}

export type ToggleProfileAction = {
  type: typeof TOGGLE_PROFILE,
  profile: Profile
}

export type EditProfileAction = {
  type: typeof EDIT_PROFILE
  profile: Profile
  data: { [index: string]: any }
}

export type RemoveProfileAction = {
  type: typeof REMOVE_PROFILE,
  profile: Profile
}

export type EditAuxCollAction = {
  type: typeof EDIT_AUX_COOLING
  data: { [index: string]: any }
}

export type Action =
  | MakeRoomAction
  | CreateTimerAction
  | EditTimerAction
  | ToggleTimerAction
  | RemoveTimerAction
  | CreateProfileAction
  | ToggleProfileAction
  | RemoveProfileAction
  | EditProfileAction
  | EditAuxCollAction

export type Timer = {
  [index: string]: any,
  targetCharge: number,
  charge: boolean,
  precool: true,
  temperature: number,
  date: Date,
  repeating: boolean,
  weekdays: { label: string }[]
}

export type Profile = {
  [index: string]: any,
  id: object,
  name: string,
  address: string | undefined,
  minCharge: number,
  optimized: boolean,
  start: Date
  end: Date
}

export type ChargingScreenState = {
  makeRoomForFullScreenComponent: boolean
  timers: Timer[],
  selectedTimers: Timer[],
  profiles: Profile[],
  selectedProfiles: Profile[],
  precondition: { label: string },
  temperature: number,
  auxiliaryZones: { label: string }[],
  remote: boolean,
}
