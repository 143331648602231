import { useStore } from "react-redux"
import { useTranslation } from "react-i18next"

import definitions from "@pag/center/views/settingsScreen/definitions"

const [kilometer, mile, meter, foot] = definitions.distanceUnits;

function useUnitFormat(name: string) {
  const store = useStore()
  const { t } = useTranslation()

  const unit = store.getState().settings["Settings_System_Units_" + name.slice(0, 1).toUpperCase() + name.slice(1) ]

  // TODO(kirill): Figure out how to make it work for other units.
  const detailedUnit = (
    unit === kilometer
    ? meter
    : (
      unit === mile
      ? foot
      : undefined
    )
  )

  return function format(num: number): string {
    return (
      (detailedUnit !== undefined && num < unit.min)
      // @ts-ignore NOTE(kirill): Because TS couldn’t see that there’s a check for undefined.
      ? `${detailedUnit.formatter(num)} ${t(detailedUnit.label)}`
      : `${unit.formatter(num)} ${t(unit.label)}`
    )
  }
}

export default useUnitFormat
